export default {
  'nav.account': 'Centrul personal',
  'nav.language': 'Chineză simplificată',
  'signup.success': 'Trimite cu succes',

  //eroare
  'contact.empty': 'Informațiile de contact nu pot fi goale',
  'phone.country.empty': 'Prefixul telefonului nu poate fi gol',
  'phone.empty': 'Telefonul nu poate fi gol',
  'email.valid': 'Vă rugăm să introduceți adresa de e-mail corectă',

  // baza
  'base.success': 'succes',
  'base.save': 'Salvare',
  'base.slide': 'Trageți cursorul pentru a verifica',
  'base.send.code': 'Trimite codul de verificare',
  'base.send.time': '({time}) retrimite codul de verificare',
  'base.send.success': 'Trimite cu succes',
  'base.view.more': 'Vedeți mai multe',
  'base.Language': 'Language',
  'base.previous': 'Pagina anterioară',
  'base.next': 'Pagina următoare',
  'base.total.page': 'Total {page} pagini',
  'base.go.to.page': 'Mergeți la pagină',
  'base.go': 'mergi la',
  'base.followers': 'Uderitori',
  'base.follow': 'urmare',
  'base.following': 'Urmat',
  'base.visit.store': 'Răsfoiește magazinul',
  'base.contact': 'Contactați-ne',
  'base.delivery': 'Timp de livrare estimat: 1-7 zile',
  'base.search': 'Căutare',
  'base.search.text': 'Introduceți căutarea prin cuvinte cheie',
  'base.server': 'Serviciul clienți online',

  //index
  'text.categories.title': 'Categoria de produs',
  'text.welcome': 'Bine ați venit la cumpărături!',
  'text.wishlist': 'Lista de aprecieri',
  'text.account': 'Centrul personal',
  'text.orders': 'Comenzi',
  'text.messages': 'Mesaje',
  'text.suggest.title': 'Ofertă exclusivă',
  'text.suggest.content': 'Numai pentru noii membri premium globale!',
  'text.super.deals': 'Produse de top, prețuri incredibile.',

  //Inscrie-te
  'signup.register': 'Înregistrare',
  'signup.signin': 'Autentificare',
  'signup.store.signin': 'Autentificare la afaceri',
  'signup.sign.out': 'Deconectare',
  'signup.email': 'Adresă de e-mail',
  'signup.password': 'Parola',
  'signup.qr.password': 'Confirmați parola',
  'signup.confirm.password': 'Confirmați parola',
  'signup.forgot.password': 'Am uitat parola',
  'signup.invitation': 'Cod de invitație',
  'signup.char': 'Personaj',
  'signup.contains': 'Conține numere, litere sau simboluri',
  'signup.qr.contains': 'Cele două parole sunt inconsecvente',
  'signup.create.account': 'Creează cont',
  'signup.agree': 'Creează un cont, ești de acord',
  'signup.member.agreement': 'Acord de membru',
  'signup.and': 'și',
  'signup.privacy.policy': 'Politica de confidențialitate',
  'signup.email.code': 'Verificare e-mail',
  'signup.last.step': 'Ultimul pas',
  'signup.send.email': 'Vă rugăm să introduceți codul din 4 cifre trimis către {email}',
  'signup.modify.email': 'Modifică adresa de e-mail',
  'signup.verify.email': 'Verificați adresa de e-mail',
  'signup.have.store': 'Aveți cont de comerciant',
  'signup.goto.signin': 'Autentificare la afaceri',
  'signup.no.store': 'Fără cont de comerciant',
  'signup.goto.store': 'Înscrieți-vă comerciant',
  'signup.next': 'Următorul',
  'signup.your.email': 'e-mailul dumneavoastră',
  'signup.code.text': 'Cod de verificare',
  'signup.submit.signup': 'Înscrie-te acum',
  'signup.smrz': 'Autentificare cu nume real',
  'signup.derb': 'Al doilea pas',
  'signup.qsrxm': 'Vă rugăm să introduceți numele documentului',
  'signup.qsrhm': 'Vă rugăm să introduceți numărul de identificare',

  //uitat
  'forgot.title': 'Resetează parola',
  'forgot.btn.check': 'Verificați adresa de e-mail',
  'forgot.reset.now': 'Resetează acum',

  //magazin
  'store.info.open': 'Deschis {y} săptămâni',

  // subsol
  'footer.great.value': 'O mare valoare',
  'footer.great.value.desc': 'Oferim prețuri competitive pentru peste 100 de milioane de articole.',
  'footer.shopping': 'Cumpărături globale',
  'footer.shopping.desc': 'Livram în peste 200 de țări și regiuni, iar site-ul nostru web este disponibil în 7 limbi.',
  'footer.safe.payment': 'Plată sigură',
  'footer.safe.payment.desc': 'Plătiți cu cele mai populare și mai sigure metode de plată din lume.',
  'footer.shop.with.confidence': 'Cumpărați cu încredere',
  'footer.shop.with.confidence.desc': 'Politica noastră de protecție a cumpărătorilor acoperă întregul proces de achiziție',
  'footer.help.center': 'Centrul de ajutor',
  'footer.help.center.desc': 'Asistență pe orice vreme pentru a crea o experiență de cumpărături fără probleme.',
  'footer.terms.conditions': 'Termeni și condiții',
  'footer.return.policy': 'Politica de returnare',
  'footer.support.policy': 'Politica de asistenţă',
  'footer.privacy.policy': 'Politica de confidențialitate',
  'footer.be.seller': 'Deveniți vânzător',
  'footer.apply.now': 'Aplicați acum',
  'footer.stay.connected': 'Rămâneți conectat',

  'footer.about.us': 'Despre noi',
  'footer.about.company': 'Profil de companie',
  'footer.about.video': 'Prezentare video',
  'footer.contact': 'Informații de contact',

  'footer.my.account': 'Contul meu',
  'footer.my.logout': 'Deconectare',
  'footer.my.order': 'Istoricul comenzilor',
  'footer.my.wish': 'Lista mea de dorințe',
  'footer.my.join': 'Deveniți partener membru',
  'footer.email': 'E-mail',
  'footer.gfemail': 'E-mail oficial',
  'footer.fwemail': 'E-mail de serviciu',
  'footer.address': 'Adresă',

  'apply.success': 'Aplicație reușită',
  'apply.success.desc': 'Aplicat cu succes, accesați contul de conectare',

  // categorii
  'category.title': 'Categorii înrudite',
  'category.all': 'Toate categoriile',

  //detaliu
  'detail.store.home': 'Magazin de pornire',
  'detail.sale.items': 'Lista de produse',
  'detail.recommend': 'Recomanda',
  'detail.orders': 'Vânzări',
  'detail.quantity': 'Cantitate',
  'detail.pieces.available': 'Disponibil',
  'detail.delivery': 'Livrare',
  'detail.free.shipping': 'Livrare gratuită',
  'detail.estimated.delivery': 'Sosire estimată',
  'detail.days': 'zile',
  'detail.buy.now': 'Cumpără',
  'detail.add.to.cart': 'Adaugă în coș',
  'detail.buyer.protection': 'Protecția cumpărătorului',
  'detail.money.guarantee': 'Garanție de returnare a banilor',
  'detail.refund.desc': 'Obțineți o rambursare completă dacă articolul nu este conform descrierii sau nu este livrat',
  'detail.description': 'Descrierea produsului',
  'detail.customer.reviews': 'Recenzii clienți',
  'detail.specifications': 'Specificații de produs',
  'detail.top.selling.products': 'Produse cele mai vândute',
  'detail.recommended.for.you': 'Recomandat pentru tine',
  'detail.sold': 'Vânzări',
  'detail.receipt': 'Confirmați primirea',
  'detail.receipt.title': 'Confirmați primirea comenzii? ',
  'detail.receipt.content': 'După confirmare, comanda tranzacției este finalizată',
  'detail.comment': 'Comentariu',
  'detail.refund.title': 'Confirmați cererea de rambursare',
  'detail.refund.content': 'După confirmare, comanda va solicita o rambursare',
  'detail.sqtk': 'Solicitați rambursarea',

  //a confirma
  'confirm.shipping.address': 'Adresa destinatarului',
  'confirm.change': 'Schimbare',
  'confirm.payment.methods': 'Metode de plată',
  'confirm.summary': 'Decontare',
  'confirm.total.item.costs': 'Total articole',
  'confirm.total': 'Total',
  'confirm.checkout': 'Mergeți la checkout',
  'confirm.place.order': 'Plasați o comandă acum',
  'confirm.pay.now': 'Plătește acum',
  'confirm.order.desc': 'După ce am făcut clic pe "Plasează comandă", confirm că am citit și confirm',
  'confirm.order.policy': 'Toți termenii și politicile',
  'confirm.payment': 'Global Shopping vă asigură securitatea informațiilor și a plăților',
  // abordare
  'address.title': 'Adresa de primire',
  'address.default': 'implicit',
  'address.edit': 'Editează',
  'address.delete': 'Șterge',
  'address.new': 'Creează o nouă adresă',
  'address.contact': 'Contact',
  'address.address': 'Adresă',
  'address.phone': 'Număr de telefon',
  'address.set.default': 'Setați implicit',
  'address.confirm': 'Confirmați',
  'address.cancel': 'Anulare',
  'address.del.title': 'Confirmați pentru a șterge adresa?',
  'address.del.content': 'Sunteți sigur că doriți să ștergeți această adresă de expediere?',

  'payment.method': 'Metoda de plată',

  // Cărucior de cumpărături
  'shopping.title': 'Coș de cumpărături',
  'shopping.back.buy': 'Înapoi la cumpărături speciale',
  'shopping.start.shopping': 'Începeți cumpărăturile',

  // membru
  'member.account': 'Centrul personal',
  'member.overview': 'Prezentare generală',
  'member.orders': 'Comenzi',
  'member.payment': 'Portofelul meu',
  'member.address': 'Adresa de primire',
  'member.wishlist': 'Lista de aprecieri',
  'member.followlist': 'Urmărește lista',
  'member.message.center': 'Centrul de mesaje',
  'member.setting': 'Setări personale',
  'member.shop.info': 'Informații despre magazin',
  'member.shop.setting': 'Setări magazin',
  'member.order.notify': 'Notificare de comandă nouă',
  'member.order.tips': 'Prin trimiterea de e-mailuri către e-mailul contului comerciantului',

  // membru.ordine
  'member.order.title': 'Comanda mea',
  'member.order.view.all': 'Vedeți toate',
  'member.order.all': 'Toate',
  'member.order.unpaid': 'neplătit',
  'member.order.paid': 'Plătit',
  'member.order.procurement': 'de cumpărat',
  'member.order.seller.paid': 'Achiziționat',
  'member.order.processing': 'de procesat',
  'member.order.shipped': 'expediat',
  'member.order.completed': 'Finalizat',
  'member.order.refund': 'Rambursat',
  'member.order.all.time': 'toate',
  'member.order.empty': 'Încă nu există comandă',
  'member.order.date': 'data',
  'member.order.purchase.date': 'Cumpărare',
  'member.order.cpoy': 'Copiere',
  'member.order.id': 'Comanda',
  'member.order.detail': 'Detalii comandă',
  'member.order.logistics': 'Informații de logistică',

  // membru.plata
  'member.payment.title': 'Portofelul meu',
  'member.wallet.balance': 'sold portofel',
  'member.crypto.recharge': 'Reîncărcare',
  'member.crypto.withdrawal': 'Retragere',
  'member.crypto.bank': 'Card bancar',
  'member.wallet.record': 'înregistrarea depozitului de monede',
  'member.bankcard.record': 'Înregistrare card bancar',
  'member.withdrawal.record': 'Înregistrare de retragere',
  'member.income.record': 'înregistrarea veniturilor',
  'member.transaction.record': 'Înregistrarea tranzacției',
  'member.wallet.freeze': 'Înghețați fondurile',
  'member.wallet.profit': 'Câștiguri estimate',

  //REÎNCĂRCĂ
  'recharge.currency': 'Moneda',
  'recharge.protocol': 'Protocol',
  'recharge.qrcode': 'Cod QR',
  'recharge.address': 'Adresă portofel',
  'recharge.copy': 'Copiați adresa',
  'recharge.ok.text': 'Confirmați',
  'recharge.cancel.text': 'Anulează',

  //bancă
  'bank.recharge.title': 'Reîncărcare card bancar',
  'bank.title': 'Numele băncii',
  'bank.name': 'Numele beneficiarului',
  'bank.account': 'Cont de primire',
  'bank.routing': 'Număr de rutare a băncii',
  'bank.code': 'Cod bancar',
  'bank.bankname': 'Numele băncii',
  'bank.bankaddress': 'Adresa băncii',
  'bank.ok.text': 'Următorul',
  'bank.ok.prev': 'Pasul anterior',
  'bank.submit': 'Trimiteți acum',
  'bank.amount': 'Suma de reîncărcare',
  'bank.amount.desc': 'Vă rugăm să introduceți suma de reîncărcare',
  'bank.type': 'tip',
  'bank.usd': 'USD',
  'bank.eur': 'Euro',
  'bank.receipt.number': 'Numărul chitanței',
  'bank.receipt.number.desc': 'Vă rugăm să introduceți numărul chitanței tranzacției',
  'bank.credential.picture': 'Imagine de acreditare',
  'bank.credential.picture.desc': 'Vă rugăm să încărcați poza de autentificare',
  'bank.remark': 'Remarcă',
  'bank.upload': 'Încărcați imagini',
  'bank.text.title': 'Note',
  'bank.text.t1': '1. Nu aveți nevoie de observații pentru transfer, lăsați-l necompletat',
  'bank.text.t2': '2. Selectați Hong Kong ca țară a gospodăriei din Hong Kong, nu adăugați China',
  'bank.text.t3': '3. Remitențe din SUA, în zilele lucrătoare, remitențe către Europa înainte de ora 15:00, remitențele sunt disponibile în timpul programului de lucru al băncii!',
  'bank.text.t4': '4. Timpul de plată este T+1, iar cea mai recentă plată este T+3 în Europa',
  'bank.text.t5': '5. Informați platforma înainte de a transfera bani pentru a confirma dacă contul este disponibil. Dacă contul este închis, nu se va plăti nicio compensație.',
  'bank.text.t6': '6. Depuneți prin card bancar, toate informațiile despre serviciul clienți vor prevala.',

  //listă bancară
  'bank.list.title': 'Numele băncii',
  'bank.list.code': 'Cod bancar',
  'bank.list.amount': 'Suma de transfer',
  'bank.list.number': 'Număr chitanță',
  'bank.list.img': 'Imaginea voucherului',
  'bank.list.status': 'Stare',
  'bank.list.time': 'ora',
  'bank.list.status1': 'În curs de examinare',
  'bank.list.status2': 'Aproape',
  'bank.list.status3': 'Respins',

  // Retragere
  'withdrawal.address.desc': 'Vă rugăm să introduceți adresa portofelului!',
  'withdrawal.number': 'Număr',
  'withdrawal.real.number': 'sosire reală',
  'withdrawal.number.desc': 'Vă rugăm să introduceți suma retragerii!',
  'withdrawal.btn.all': 'toate',
  'withdrawal.balance': 'Sold',
  'withdrawal.commission': 'comision',
  'withdrawal.actual.amount': 'Contabilizat',
  'withdrawal.notice': 'Prompt',
  'withdrawal.notice.text': 'Înainte de transfer, vă rugăm să confirmați că informațiile despre adresa de primire sunt corecte. Odată ce activele sunt transferate, acestea nu pot fi returnate.',
  'withdrawal.notice.content': '{name}({cp_name}) comision: valoarea de piață actuală {fee_rate}%/pen, standard minim: {fee_min} {name}/pen',
  'withdrawal.submit': 'Trimite',
  'withdrawal.choice': 'Alegeți o criptomonedă',
  'withdrawal.yzm': 'Cod de verificare',
  'withdrawal.fs': 'Trimite',
  'withdrawal.qsryzm': 'Vă rugăm să introduceți codul de verificare a e-mailului',

  // recive
  'recive.method': 'Protocol',
  'recive.amount': 'Suma',
  'recive.address': 'Adresă',
  'recive.date': 'ora',
  'recive.status': 'Stare',
  'recive.create.at': 'Timp tranzacție',
  'recive.type': 'Tip',
  'recive.befor': 'înainte de tranzacție',
  'recive.balance': 'Sold',
  'recive.freeze': 'Înghețare',
  'recive.review': 'Revizuire',
  'recive.success': 'Succes',
  'recive.reject': 'Respins',

  // face reclamă
  'advertise.title': 'Centrul de promovare',
  'advertise.shop.title': 'Promoția magazinului',
  'advertise.shop.status': 'Stare',
  'advertise.shop.expired': 'Expirat',
  'advertise.shop.promotion': 'Promovare',
  'advertise.shop.expire.date': 'Data de expirare',
  'advertise.shop.renew': 'Reînnoirea reclamei',
  'advertise.shop.payable': 'Suma de plătit',
  'advertise.shop.explanation': 'Explicația reclamei',
  'advertise.shop.text': 'Participă la promovarea publicității pe platformă, crește expunerea magazinului și promovează comenzile de tranzacții',
  'advertise.shop.paynow': 'Reînnoiește acum',
  'advertise.shop.modal.title': 'Confirmați plata',
  'advertise.shop.modal.desc': 'Confirmat pentru plata taxei de promovare',
  'advertise.shop.modal.btn': 'Confirmați plata',

  //Lista de dorințe
  'wishlist.title': 'Wishlist',
  'wishlist.delete': 'Șterge',
  'wishlist.orders': 'Vânzări',

  //Urmărește Lista
  'followlist.title': 'Urmărește Lista',
  'followlist.delete': 'Șterge',
  'followlist.follow': 'Urmărire',

  // magazin
  'store.dashboard': 'Tabloul de bord',
  'store.products': 'Produse',
  'store.products.list': 'Lista de produse',
  'store.products.reviews': 'Recenzii despre produse',
  'store.orders': 'Comenzi',
  'store.wallet': 'Portofel',
  'store.message': 'Centrul de mesaje',
  'store.setting': 'setare',
  'store.order.total.profit': 'Venit total din exploatare estimat',

  //bord
  'dashboard.store.hour.views': 'Vizite în timp real',
  'dashboard.store.today.views': 'Vizitele de astăzi',
  'dashboard.product.total': 'Produs total',
  'dashboard.product.today': 'Nou astăzi',
  'dashboard.order.total': 'Comanda totală',
  'dashboard.sales.total': 'Cifra de afaceri estimată',
  'dashboard.sales.real': 'Vânzări reale',
  'dashboard.sales.pay': 'Plătire totală',
  'dashboard.sales.profit': 'Profit total',
  'dashboard.commission.total': 'Venit total',
  'dashboard.commission.today': 'Câștiguri de astăzi',
  'dashboard.order.sales': 'Vânzări de mărfuri',
  'dashboard.sales.list': 'Clasarea vânzărilor',
  'dashboard.goods.cate.rate': 'Raport de clasificare a produselor',
  'dashboard.goods.wish': 'Clasarea produselor ca produse',
  'dashboard.january': 'ianuarie',
  'dashboard.february': 'februarie',
  'dashboard.march': 'Martie',
  'dashboard.april': 'aprilie',
  'dashboard.may': 'Mai',
  'dashboard.june': 'iunie',
  'dashboard.july': 'iulie',
  'dashboard.august': 'August',
  'dashboard.september': 'septembrie',
  'dashboard.october': 'Octombrie',
  'dashboard.november': 'noiembrie',
  'dashboard.december': 'Decembrie',

  // produse
  'products.add.new': 'Adăugați un produs nou',
  'products.add.from.warehouse': 'Adăugați produse din depozit',
  'products.delete': 'Șterge',
  'products.add': 'Adăugați',
  'products.table.img': 'imagine',
  'products.table.name': 'nume produs',
  'products.table.category': 'Categorie',
  'products.table.wish': 'like',
  'products.table.stock': 'stoc',
  'products.table.price': 'Preț',
  'products.table.profit': 'Profit',
  'products.table.action': 'Acțiune',
  'products.search.category': 'Căutare după categorie',
  'products.back.product': 'Înapoi la lista de produse',
  'products.total': 'Total bunuri',
  'products.yes': 'Da',
  'products.no': 'Anulează',
  'products.batch.add': 'Creșterea lotului',
  'products.ask.add': 'Sunteți sigur că doriți să adăugați produse?',
  'products.batch.delete': 'Ștergerea lotului',
  'products.ask.delete': 'Sunteți sigur că doriți să ștergeți produsul?',
  'products.top': 'Sus',
  'products.syzd': 'Începutul paginii de pornire',
  'products.zdwz': 'Poziția de sus',
  'products.t1': 'Promoție',
  'products.t2': 'De preferat',
  'products.t3': 'Boutique',
  'products.t4': 'niciunul',
  // recenzii
  'reviews.title': 'Recenzii despre produse',
  'reviews.product.name': 'Produs',
  'reviews.user.name': 'Utilizator',
  'reviews.star': 'Evaluare',
  'reviews.comment': 'Comentează conținutul',
  'reviews.sku': 'Specificațiile produsului',
  'reviews.imgs': 'Atlas',
  'reviews.created': 'Data',

  // magazin.comandă
  'store.order.purchase': 'Cumpără acum',
  'store.order.purchase.desc': 'Sunteți sigur că doriți să plătiți pentru acest produs?',
  'store.order.purchase.yes': 'Plătiți acum',
  'store.order.purchase.no': 'Anulează',
  'store.order.desc': 'Achiziționarea acestei comenzi necesită plată și veți obține beneficii după finalizarea comenzii. ',
  'store.order.no': 'Număr comandă',
  'store.order.number': 'Cantitate',
  'store.order.buyer': 'Cumparator',
  'store.order.total': 'Suma totală',
  'store.order.will.earning': 'Venit',
  'store.order.profit': 'Profit',
  'store.order.dividends': 'Dividende',
  'store.order.payment.status': 'Starea plății',
  'store.order.seller.buy.status': 'Starea achiziției',
  'store.order.status': 'Starea comenzii',
  'store.order.date': 'data comenzii',
  'store.order.purchase.date': 'Data achiziției',
  'store.order.action': 'Operare',
  'store.order.purchase.amount': 'Suma achiziției',

  //sursa de venit
  'income.create.at': 'Timp de înregistrare',
  'income.order.sn': 'Numărul comenzii',
  'income.realpay': 'Suma comandă',
  'income.profit': 'venit',

  //Setare
  'setting.avatar': 'Avatar de afaceri',
  'setting.upload': 'Încărcare',
  'setting.shop.name': 'Numele magazinului',
  'setting.shop.phone': 'Telefon de contact',
  'setting.shop.address': 'Adresa magazinului',
  'setting.shop.save': 'Salvare',
  'setting.upload.pic': 'Încarcă imaginea',
  'setting.send.pic': 'Trimite poza',

  //beseller
  'beseller.title': 'Solicitați pentru a fi comerciant',
  'beseller.account': 'Informații despre cont',
  'beseller.store': 'Informații magazin',
  'beseller.store.name': 'Numele magazinului',
  'beseller.store.address': 'Adresa magazinului',

  //salvează acasă
  'store.home.title': 'Setări pentru pagina de pornire',
  'store.home.topimg': 'imaginea de sus',
  'store.home.banner': 'Imagine carusel',
  'store.home.up3': 'Observații: cel puțin 3 încărcări',
  'store.upload.more': 'Încărcați mai multe imagini',
  'store.home.columns': 'Coloane',
  'store.home.bgimg': 'Imagine de fundal',
  'store.goods.remark': 'Observație: 5 articole pe rând, cel mult 10 articole pe farfurie',
  'store.home.select': 'Selectați produsul',
  'store.home.add': 'Adăugați coloană',

  //Informații personale
  'setting.update.user': 'Informații personale',
  'setting.update.info': 'Modificați informații',
  'setting.user.avatar': 'Avatar utilizator',
  'setting.user.nickname': 'Pseudonim utilizator',
  'setting.user.nickname.desc': 'Vă rugăm să introduceți porecla utilizatorului',

  'setting.safe.title': 'Informații de siguranță',
  'setting.user.passwd': 'Schimbați parola',
  'setting.passwd.title1': 'Parolă veche',
  'setting.passwd.desc1': 'Vă rugăm să introduceți vechea parolă',
  'setting.passwd.title2': 'Parolă nouă',
  'setting.passwd.desc2': 'Vă rugăm să introduceți o nouă parolă',
  'setting.passwd.title3': 'Confirmați parola',
  'setting.passwd.desc3': 'Vă rugăm să introduceți o parolă de confirmare',
  'setting.invitation': 'Invitați prieteni',
  'setting.copy': 'Copiere',

  'adv.add.products': 'Adăugați produse promoționale',
  'adv.list.title': 'Promovarea produsului',
  'adv.type': 'Tipul de promovare',
  'adv.begin.time': 'Timp de promovare',
  'adv.end.time': 'Ora de încheiere',
  'adv.status': 'Stare',
  'adv.status.s1': 'Promovare',
  'adv.status.s2': 'Sfârșit',
  'adv.add.back': 'Reveniți la lista promovată',
  'adv.recharge': 'Reîncărcare puncte de promovare',
  'adv.select.goods': 'Selectați bunuri',
  'adv.select.btn': 'Produse promovate',
  'adv.recharge.title': 'Reîncărcare puncte de promovare',
  'adv.recharge.balance': 'Sold de puncte',
  'adv.point': 'punct',
  'adv.point.rate': 'Raport de puncte',
  'adv.recharge.amount': 'Suma de reîncărcare',
  'adv.recharge.input.amount': 'Introduceți suma de reîncărcare',
  'adv.select.mod': 'Selectați panoul',
  'adv.mod1': 'Coloane recomandate',
  'adv.mod2': 'Coloană preferată',
  'adv.mod3': 'Coloană cu vânzări foarte bune',
  'adv.mod4': 'Lista excelentă',
  'adv.mod5': 'Lista de clasificare',
  'adv.mod6': 'Promoție detaliată',
  'adv.ge': 'Cantitate',
  'adv.xs': 'ora',
  'adv.xzbk': 'Selectați farfuria',
  'adv.syme': 'Cota rămasă',
  'adv.tgsc': 'Durata promoției',
  'adv.tgjg': 'Prețul promoției',
  'adv.syye': 'Sold actual',
  'adv.tgye': 'Schimul promoției',
  'adv.yj1': 'așteptată',
  'adv.yj2': 'Începe promovarea',
  'adv.pay.point': 'Puncte de consum',

  'wallet.ykcsxf': 'Taxa de gestionare a fost dedusă',
  'wallet.sxf': 'Taxa de serviciu',
  'wallet.zxje': 'Suma minimă',
  'wallet.24hxe': 'limită de 24 de ore',
  'wallet.desc.text': 'În nicio circumstanță, oficialul nu vă va cere să transferați bani într-un cont și nici nu vă va cere un cod de verificare. Nu participați la activități ilegale, cum ar fi cumpărarea în numele altora, spălarea banilor și strângerea ilegală de fonduri și ferește-te de frauda online',
  'wallet.cunbi.text': 'Puteți depune doar {name} la această adresă, alte active nu vor fi recuperate',

  'credit.title': 'Limita de credit',
  'credit.ed': 'Cotă',
  'credit.hk': 'Rambursare',
  'credit.kyye': 'Sold disponibil',
  'credit.dqqk': 'Arierate curente',
  'credit.hkje': 'Suma de rambursare',
  'credit.hkje.desc': 'Vă rugăm să introduceți suma de rambursare',
  'credit.cgje': 'Suma de achiziție',
  'credit.qrz': 'Mergeți la autentificare',
  'credit.srxyj': 'Introduceți suma plății creditului',

  'store.gzl': 'Suma de atenție',
  'store.wxz': 'Nelimitat',

  'auth.smrz': 'Autentificare cu nume real',
  'auth.rzlx': 'Tip',
  'auth.sfzm': 'Fața cărții de identitate',
  'auth.sffm': 'Reversul cărții de identitate',
  'auth.zsxm': 'nume real',
  'auth.zjhm': 'Numărul documentului',
  'auth.yyzz': 'Licență de afaceri',
  'auth.gsmc': 'Numele companiei',
  'auth.shz': 'Audit',
  'auth.ytg': 'Aproape',
  'auth.ybh': 'Respins',
  'auth.zt': 'stare',
  'auth.gr': 'Personal',
  'auth.gs': 'Întreprindere',
  'auth.ljtj': 'Solicitați autentificare',
  'auth.wrz': 'Neautentificat',

  'credit.qts': 'Vedeți descrierea',
  'store.spss': 'Limita superioară a mărfurilor',
  'store.dpfh': 'Dividendele stocate',
  'store.qydj': 'Nivelul capitalului propriu',
  'store.level': 'nivel',
  'store.jian': 'bucăți',

  'order.notify.email': 'Datorită controlului riscului prin e-mail, e-mailurile de memento pentru comenzile platformei vor fi interceptate frecvent. Pentru a le primi la timp, se recomandă să adăugați {email} la lista albă de e-mail de conectare',

  'setting.sjxx': 'Informații despre companie',
  'setting.smrz': 'Autentificare cu nume real',
  'setting.aqzx': 'Centrul de securitate',
  'setting.tzxx': 'Informații de notificare',
  'setting.dpzx': 'Pagina principală de decorare',
  'setting.sjqy': 'Interese de afaceri',
  'setting.sjdjqy': 'Descrierea beneficiilor la nivel de afaceri',

  'setting.fhbky': 'Dividendul actual nu este disponibil',
  'setting.spgzyzy': 'Regulile și regulile magazinului',

  'desc.dqqy': 'Capitalul curent',
  'desc.xjqy': 'Interese subordonate',
  'desc.sjqy_0': 'Limita de credit pentru stocare',
  'desc.sjqy_1': 'Storezi dividendele',
  'desc.sjqy_2': 'Numărul de produse care pot fi publicate',

  'share.yqlb': 'Lista de invitații',
  'share.zmj': 'Vânzător total',
  'share.zdd': 'Comanda totală',
  'share.zsy': 'Venitul total',
  'share.wcl': 'neprocesat',
  'share.ycl': 'Procesat',
  'share.ywc': 'Finalizat',
  'share.dpmc': 'Numele magazinului',
  'share.ddl': 'Cantitatea comenzii',
  'share.jjsy': 'Venit din brokeraj',
  'share.zts': 'Număr total',

  'chat.state': 'stat',
  'chat.zx': 'Online',
  'chat.ljz': 'Se conectează',

  'bind.wallet.title': 'Adresă de retragere',
  'bind.address.title': 'Adăugați adresa',
  'bind.bj': 'Editează',
  'bind.sc': 'Șterge',
  'bind.qrsc': 'Sunteți sigur că doriți să ștergeți această adresă?',
  'bind.qbdz': 'Adresă portofel',
  'bind.tjsj': 'Adăugați ora',
  'bind.cz': 'Operare',
  'withdrawal.address.choice': 'Vă rugăm să alegeți adresa de retragere!',

  'order.sxsj': 'Timp efectiv',
  'order.ljsj': 'eficient imediat',
  'order.dssj': 'Efectuos cronometrat',

  'store.st.zt': 'Starea magazinului',
  'store.st.zc': 'normal',
  'store.st.zc.desc': 'Magazinul dvs. este normal, vă rugăm să continuați așa',
  'store.st.yc': 'Excepție',
  'store.st.yc.desc': 'Magazinul dvs. are unele comenzi anormale sau au primit reclamații ale clienților',
  'store.st.dj': 'Înghețați',
  'store.st.dj.desc': 'Magazinul dumneavoastră are un anumit risc de funcționare și fondurile sunt înghețate',
  'store.st.jy': 'dezactivat',
  'store.st.jy.desc': 'Magazinul dvs. a încălcat acordurile și regulile legate de platformă și a fost dezactivat',

  'task.sy': 'Pagina de pornire',
  'task.rwlbo': 'Lista de sarcini',
  'task.rwxqo': 'Detaliile sarcinii',
  'task.ljcy': 'Participă acum',
  'task.gdrw': 'Mai multe sarcini',
  'task.rwlb': 'Lista sarcinilor de preluare',
  'task.qbrw': 'Toate sarcinile',
  'task.wcyd': 'Am participat la',
  'task.qdz': 'Primirea comenzilor',
  'task.ywc': 'Finalizat',
  'task.djy': 'de tranzacționat',
  'task.ddje': 'Suma comandă',
  'task.cyrs': 'Număr de participanți',
  'task.ewsy': 'Avantaje suplimentare',
  'task.jzsj': 'Termen limită',
  'task.ckxq': 'Vedeți detalii',
  'task.rwxq': 'Detalii activitate',
  'task.cylb': 'Lista de participare',
  'task.qdcyrw': 'Sunteți sigur că doriți să participați la această sarcină de comandă?',
  'task.sd': 'Da',
  'task.bue': 'nu',

  'task.wdsy': 'Venituri',
  'task.wdfh': 'Dividende',
  'task.wdqd': 'Obțineți comanda',
  'task.dd.leixin': 'Tipul de comandă',
  'task.dd.qdrw': 'Sarcina de comandă rapidă',
  'task.dd.ptdd': 'Ordine obișnuită',

  'flow.lltg': 'Promovarea fluxului',
  'flow.gmll': 'Achiziționați pachet de date',
  'flow.dqtc': 'Pachetul actual',
  'flow.tc1': 'Pachet',
  'flow.sy2': 'remaining',
  'flow.tian3': 'zi',
  'flow.jlzs': 'Numărul total de înregistrări',
  'flow.lltc': 'Pachet flux',
  'flow.ll': 'flux',
  'flow.gmsj': 'Timp de cumpărare',
  'flow.tcjg': 'Prețul pachetului',
  'flow.sjzf': 'plată reală',
  'flow.tcsm': 'Descrierea pachetului',
  'flow.tcsm.sm': 'Achiziționarea acestui pachet va crește expunerea magazinului și va câștiga mai mult trafic de clienți. Pentru a obține mai multe comenzi și a crește veniturile din magazin. ',

  'flow.tcmc': 'Numele pachetului',
  'flow.gmsc': 'Durata achiziției',
  'flow.zje': 'Suma totală',
  'flow.jzsj': 'Termen limită',
  'flow.gmrq': 'Data achiziției',
  'flow.tian1': 'zi',
  'flow.zhou1': 'săptămână',
  'flow.yue1': 'lună',

  'share.dj': 'nivel',

  'share.hz': 'Transfer',
  'share.hzd': 'Transfer la',
  'share.qbye': 'sold portofel',
  'share.tgje': 'Suma promoției',
  'share.hzje': 'Suma de transfer',
  'share.qsrhzje': 'Vă rugăm să introduceți suma de transfer',

  '_inv._title': 'Descrierea invitației',
  '_inv._t1': '1. Invitație și relație invitată',
  '_inv._desc1': 'A îl invită pe B, B îl invită pe C, C îl invită pe D. Cel mai înalt nivel este 4, dacă D îl invită din nou pe E, atunci E nu are nimic de-a face cu A. ',
  '_inv._t2': '2. Invitatorul primește o recompensă',
  '_inv._desc2': 'A primește 4% din suma totală finalizată la comanda B, A primește 2% din suma totală finalizată la comanda C și A primește 1% din suma totală finalizată la comanda D',

  '_index._gm': 'Cumpărare',
  '_index._sm': 'Descriere: Creșteți expunerea magazinului și atrageți trafic',

  '_n.dlyzc': 'Autentificare și înregistrare',
  '_n.hy4': 'Mai sunt 4',
  '_n.ckgd': 'Vezi mai multe',
  '_n._cxhzk': 'Promoții și reduceri',
  '_n._cwyp': 'Rechizite pentru animale de companie',
  '_n._tjsp': 'Ofertă specială',
  '_n._rmfl': 'Categorii populare',
  '_n._tjsp2': 'Produse recomandate',
  '_n._rxsp': 'Produse recomandate',
  '_n._rmsp': 'Articole populare',
  '_n._gdsp': 'Mai multe produse',
  '_n._yzsj': 'Comerciant de calitate',

  '_n._zxsc': 'Mall online',
  '_n._fkyys': 'Plata și livrare',
  '_n._xsgz': 'Reguli de vânzări',
  '_n._hhjth': 'Schimburi și returnări',
  '_n._lxwm': 'Contactează-ne',
  '_n._sczc': 'politica centrului comercial',
  '_n._tkytj': 'Termeni și condiții',
  '_n._tuzc': 'Politica de returnare',
  '_n._zczc': 'Politica de asistenţă',
  '_n._yszc': 'Politica de confidenţialitate',
  '_n._scjj': 'Prezentarea centrului comercial',
  '_n._ljgm': 'Cumpără acum',

  '_n2._rxsp': 'Vânzare la maxim',
  '_n2._pl': 'Comentariu',
  '_n2._sjxx': 'Informații despre companie',
  '_n2._rhsh': 'Cum să primiți mărfuri',
  '_n2._xsxd': 'Comandă online',
  '_n2._jscldd': 'Serviciul specializat pentru clienți',
  '_n2._mfps': 'Livrare gratuită',
  '_n2._2xsfh': 'Livrare fulger',
  '_n2._1d5tdd': 'Sosire în 1-5 zile',
  '_n2._tkzd': 'Poștă expresă',
  '_n2._ckfh': 'Expediere din depozit',
  '_n2._zcps': 'Depozit din apropiere',
  '_n2._jsd': 'Punctul de primire',
  '_n2._qsjps': 'Livrare la nivel mondial',
  '_n2._thtj': 'Condiții de returnare',
  '_n2._thtjsm': 'Puteți schimba sau returna produsul în termen de 14 zile de la data achiziției',
  '_n2._gycp': 'Despre produs',
  '_n2._yc': 'Ascunde',
  '_n2._tiao': 'Tiao',
  '_n2._sypl': 'Toate comentariile',
  '_n2._lxfk': 'Lăsați feedback',
  '_n2._xzspgg': 'Vă rugăm să selectați specificația produsului',
  '_n2._qxdl': 'Vă rugăm să vă conectați mai întâi',
  '_n2._rnsj': 'După cum puteți vedea',
  '_n2._rx': 'Vânzare la maxim',

  '_n3._qrdd': 'Confirmați comanda',
  '_n3._njsp': '{num} articole',
  '_n3._ddzf': 'Plata comenzii',
  '_n3._wydlsp': 'Bine ați venit să vă conectați la magazin',

  '_n3._sjwfgm': 'Comerciantul nu poate cumpăra bunuri',
  '_n3._sjbnlt': 'Companiile nu pot conversa prin chat',
  '_chat._hc': 'Retragere',

  '_zc._sryxdz': 'Vă rugăm să introduceți adresa dvs. de e-mail',
  '_zc._yxgscw': 'Eroare format e-mail',
  '_zc._qsrmm': 'Vă rugăm să introduceți parola',
  '_zc._lcmmbxd': 'Două parole sunt inconsecvente',
  '_zc._hdyzwtg': 'Vă rugăm să verificați prin glisor',
  '_zc._qsryqm': 'Vă rugăm să introduceți codul de invitație',
  '_zc._yhzh': 'Înregistrați contul de utilizator',
  '_zc._qzc': 'Mergeți la înregistrare',
  '_zc._srdpm': 'Vă rugăm să introduceți numele magazinului',

  '_zc._gszz': 'Calificarea companiei',
  '_zc._ptzz': 'Calificări legate de platformă',

  '_dd._tijd': 'Se trimite comanda',
  '_dd._xzdz': 'Vă rugăm să selectați adresa de livrare',
  '_dd._zfz': 'Plata în curs',

  '_wd._zf': 'plată',
  '_wd._cz': 'Reîncărcare',
  '_wd._txsq': 'Solicitare de retragere',
  '_wd._ddsy': 'Venit din comandă',
  '_wd._cgzf': 'Cumpărare',
  '_wd._txbh': 'Respinge',
  '_wd._txtg': 'Aproape',
  '_wd._ddtk': 'Rambursarea comenzii',
  '_wd._yehz': 'Transfer',
  '_wd._gmll': 'Cumpărați trafic',
  '_wd._zjkc': 'deducere chirie',
  '_wd._fk': 'Bine',
  '_wd._ns': 'Taxa',
  '_wd._bzj': 'Marja',

  '_xxtz._zntz': 'Notificare site',
  '_xxtz._fh': 'Întoarcere',
  '_xxtz._jzgd': 'Încărcați mai multe',
  '_xxtz._mysj': 'Nu mai mult',

  '_sjsy._jrdd': 'Comanda de azi',
  '_sjsy._rxse': 'Vânzări zilnice',

  '_sjsy._xh': 'Index',

  '_wd._nbjy': 'Transfer intern',
  '_wd._qsruid': 'Vă rugăm să introduceți UID-ul beneficiarului',
  '_wd._je': 'Suma',
  '_wd._qsrhzje': 'Vă rugăm să introduceți suma de transfer',
  '_wd._skr': 'Beneficiar',
  '_wd._nbhzsm': 'Pentru a transfera intern către utilizatorii platformei, vă rugăm să verificați cu atenție informațiile despre beneficiar',

  '_st._spxlphb': 'Clasamentul vânzărilor de produse',
  '_st._czsp': 'Resetează căutarea',

  '_st._pljrgwc': 'Alăturați-vă tuturor',

  '_sj._yxz': 'Selectat',
  '_sj._hjzje': 'Suma totală',
  '_sj._zdcr': 'Depune automat',

  '_wd._ddfh': 'Dividend',

  '_dd._csqx': 'Anularea comenzii',

  '_tx._yhktx': 'Retragere bancară',
  '_tx._sryhmc': 'Vă rugăm să introduceți numele băncii',
  '_tx._srskrmc': 'Vă rugăm să introduceți numele beneficiarului',
  '_tx._srskzh': 'Vă rugăm să introduceți numărul contului de plată',
  '_tx._sryhluh': 'Vă rugăm să introduceți numărul de rutare a băncii',
  '_tx._sryhdm': 'Vă rugăm să introduceți codul bancar',
  '_tx._sryhdz': 'Vă rugăm să introduceți adresa băncii',
  '_tx._txje': 'Suma de retragere',
  '_tx._srtxje': 'Vă rugăm să introduceți suma de retragere',
  '_tx._txkc': 'Taxa de retragere {_sxf}%',

  '_yye._yyye': 'Vânzări lunare',
  '_yye._ysy': 'Venit lunar',

  '_sj._hjzsy': 'Venitul total total',

  '_yhdl._dl': 'Autentificare',
  '_yhdl._zc': 'Creează cont',

  '_zdy._24zrkf': 'Suntem aici pentru a vă ajuta 24/7',
  '_zdy._zhsz': 'Setările contului',
  '_zdy._mfps': 'Pentru o perioadă limitată, puteți obține livrare gratuită în 2 zile lucrătoare pentru mii de articole în anumite zone. ',
  '_zdy._gmsp': 'Cumpara produse noi',
  '_zdy._czyh': 'Oferte grozave, bucură-te de produsele tale preferate',
  '_zdy._xsgw': 'Cumpărături în timp limitat! Comandați înainte de 12 mai la prânz ET pentru transport gratuit. Livrat la usa ta cu o masina dedicata! ',
  '_zdy._ssgjc': 'Introduceți căutarea prin cuvinte cheie',

  '_zdy._rmjx': 'Cele mai bune alegeri ale tale',

  '_dd._plcz': 'Operațiune în lot',
  '_dd._plcg': 'Achiziție în bloc',
  '_dd._qgxcgd': 'Vă rugăm să verificați comanda pe care trebuie să o cumpărați',
  '_dd._yxddbcg': 'Comanda selectată nu necesită cumpărare',
  '_dd._qdyplcgm': 'Sunteți sigur că doriți să achiziționați comanda selectată în bloc? ,Cantitate:{n}',
  '_dd._qdcg': 'Confirmați achiziția',
  '_dd._qx': 'Anulează',
  '_dd._plcgwc': 'Achiziție în lot finalizată, cu succes: {s}/{t}',
  '_dd._zzcgz': 'Cumpărare',
  '_st._czhk': 'Reîncărcați și rambursați',
  '_st._dkje': 'sumă împrumutată',
  '_st._dkjl': 'istoric de împrumut',
  '_st._hkfs': 'Rambursare',
  '_st._hkje': 'Suma de rambursare',
  '_st._jrong': 'Finanțe Mall',
  '_st._ljsq': 'aplicati imediat',
  '_st._qrshkje': 'Vă rugăm să introduceți suma de rambursare',
  '_st._qsrdkje': 'Vă rugăm să introduceți suma împrumutului',
  '_st._shz': 'în curs de revizuire',
  '_st._sqdklx': 'împrumut',
  '_st._sqhk': 'Solicitați rambursare',
  '_st._sqhklx': 'rambursare',
  '_st._sqjk': 'Cereți un împrumut',
  '_st._sqlx': 'tip',
  '_st._ybh': 'respinge',
  '_st._yjk': 'Împrumutat',
  '_st._ytg': 'trece',
  '_st._zzhk': 'În așteptarea rambursării',

  '_st._dqed': 'Suma împrumutului',
  '_st._ccdked': 'Nu se poate depăși limita de împrumut',
  '_st._tjsqz': 'Aplicația este în curs de depunere',
  '_st._dklx': 'Dobândă',
  '_st._dkll': 'Rata dobânzii la împrumut: {n}%',
  '_kdts._ts': 'Deschis timp de {t} zile',
  '_jltg._tgje': 'Investiție în publicitate',

  '_st._yqk': 'Arierat',
  '_st._qb': 'Toate',

  '_spxx._spbm': 'cod',

  '_scrz._yyzz': 'Licenţă de afaceri'
}
