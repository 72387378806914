export default {
  'nav.account': 'المركز الشخصي ',
  'nav.language': 'الصينية المبسطة',
  'signup.success': 'تم الإرسال بنجاح',

  'contact.empty': 'لا يمكن أن تكون معلومات الاتصال فارغة ',
  'phone.country.empty': 'لا يمكن أن تكون بادئة الهاتف فارغة ',
  'phone.empty': 'لا يمكن أن يكون الهاتف فارغًا ',
  'email.valid': 'يرجى إدخال البريد الإلكتروني الصحيح',

  'base.success': 'نجاح',
  'base.save': 'يحفظ',
  'base.slide': 'الرجاء سحب شريط التمرير للتحقق ',
  'base.send.code': 'أرسل الرمز ',
  'base.send.time': '({time}) إعادة إرسال الرمز ',
  'base.send.success': 'أرسل بنجاح ',
  'base.view.more': 'عرض المزيد',
  'base.Language': 'لغة',
  'base.previous': 'الصفحة السابقة',
  'base.next': 'الصفحة التالية',
  'base.total.page': 'إجمالي عدد صفحات {page} ',
  'base.go.to.page': 'انتقل إلى صفحة',
  'base.go': 'اذهب إلى',
  'base.followers': 'متابعون',
  'base.follow': 'يتبع',
  'base.following': 'التالي',
  'base.visit.store': 'تصفح المتجر ',
  'base.contact': 'اتصل بنا',
  'base.delivery': 'وقت التسليم المقدر: 1-7 أيام ',
  'base.search': 'يبحث',
  'base.search.text': 'أدخل كلمات رئيسية للبحث ',
  'base.server': 'خدمة العملاء عبر الإنترنت',

  'text.categories.title': 'فئة المنتج',
  'text.welcome': 'مرحبًا بكم في التسوق! ',
  'text.wishlist': 'قائمة الرغبات',
  'text.account': 'المركز الشخصي ',
  'text.orders': 'طلبات',
  'text.messages': 'رسالة',
  'text.suggest.title': 'عرض حصري',
  'text.suggest.content': 'فقط للأعضاء المميزين العالميين الجدد! ',
  'text.super.deals': 'أفضل المنتجات بأسعار لا تصدق. ',

  'signup.register': 'اشتراك',
  'signup.signin': 'تسجيل الدخول',
  'signup.store.signin': 'تسجيل دخول الأعمال ',
  'signup.sign.out': 'تسجيل خروج',
  'signup.email': 'عنوان البريد الإلكتروني',
  'signup.password': 'كلمة المرور',
  'signup.qr.password': 'تأكيد كلمة المرور',
  'signup.confirm.password': 'تأكيد كلمة المرور',
  'signup.forgot.password': 'هل نسيت كلمة السر',
  'signup.invitation': 'شفرة الدعوة',
  'signup.char': 'شخصية',
  'signup.contains': 'تحتوي على أرقام أو أحرف أو رموز ',
  'signup.qr.contains': 'كلمتا المرور غير متسقتين ',
  'signup.create.account': 'إنشاء حساب',
  'signup.agree': 'إنشاء حساب , أنت توافق ',
  'signup.member.agreement': 'اتفاقية العضو ',
  'signup.and': 'و',
  'signup.privacy.policy': 'سياسة الخصوصية',
  'signup.email.code': 'تأكيد بواسطة البريد الالكتروني',
  'signup.last.step': 'اخر خطوة',
  'signup.send.email': 'الرجاء إدخال الرمز المكون من 4 أرقام المرسل إلى {email} ',
  'signup.modify.email': 'تعديل البريد الإلكتروني ',
  'signup.verify.email': 'البريد الإلكتروني للتحقق ',
  'signup.have.store': 'لديك حساب تاجر ',
  'signup.goto.signin': 'تسجيل دخول الأعمال ',
  'signup.no.store': 'لا يوجد حساب تاجر',
  'signup.goto.store': 'تسجيل التاجر',
  'signup.next': 'التالي',
  'signup.your.email': 'بريدك الالكتروني',
  'signup.code.text': 'رمز التحقق',
  'signup.submit.signup': 'أفتح حساب الأن',
  'signup.smrz': 'مصادقة الاسم الحقيقي',
  'signup.derb': 'الخطوة الثانية',
  'signup.qsrxm': 'الرجاء إدخال اسم المستند',
  'signup.qsrhm': 'الرجاء إدخال رقم الهوية',

  'forgot.title': 'إعادة تعيين كلمة المرور ',
  'forgot.btn.check': 'التحقق من البريد الإلكتروني',
  'forgot.reset.now': 'إعادة التعيين الآن ',

  'store.info.open': 'افتح {y} أسبوع',

  'footer.great.value': 'قيمة عظيمة',
  'footer.great.value.desc': 'نحن نقدم أسعارًا تنافسية لأكثر من 100 مليون عنصر. ',
  'footer.shopping': 'التسوق العالمي ',
  'footer.shopping.desc': 'نشحن إلى أكثر من 200 دولة ومنطقة ، وموقعنا الإلكتروني متاح بسبع لغات',
  'footer.safe.payment': 'الدفع الآمن ',
  'footer.safe.payment.desc': 'ادفع بأكثر طرق الدفع شيوعًا وأمانًا في العالم. ',
  'footer.shop.with.confidence': 'تسوق مع الثقة',
  'footer.shop.with.confidence.desc': 'تغطي سياسة حماية المشتري لدينا عملية الشراء بالكامل. ',
  'footer.help.center': 'مركز المساعدة',
  'footer.help.center.desc': 'مساعدة على مدار الساعة طوال أيام الأسبوع لخلق تجربة تسوق سلسة. ',
  'footer.terms.conditions': 'الأحكام والشروط',
  'footer.return.policy': 'سياسة العائدات',
  'footer.support.policy': 'سياسة الدعم ',
  'footer.privacy.policy': 'سياسة الخصوصية',
  'footer.be.seller': 'كن بائعًا ',
  'footer.apply.now': 'قدم الآن',
  'footer.stay.connected': 'ابق على اتصال',

  'footer.about.us': 'نبذة عنا',
  'footer.about.company': 'ملف تعريف الشركة',
  'footer.about.video': 'مقدمة الفيديو',
  'footer.contact': 'جهة اتصال',

  'footer.my.account': 'حسابي',
  'footer.my.logout': 'تسجيل الخروج',
  'footer.my.order': 'سجل الطلبات',
  'footer.my.wish': 'My Wish List',
  'footer.my.join': 'كن عضوًا شريكًا',
  'footer.email': 'بريد إلكتروني',
  'footer.gfemail': 'بريد إلكتروني رسمي',
  'footer.fwemail': 'البريد الإلكتروني للخدمة',
  'footer.address': 'العنوان',

  'application.success': 'التطبيق ناجح',
  'application.success.desc': 'تم التطبيق بنجاح ، انتقل إلى حساب تسجيل الدخول',

  'category.title': 'فئة ذات صلة ',
  'category.all': 'جميع الفئات',

  'detail.store.home': 'مخزن البيت',
  'detail.sale.items': 'قائمة البند',
  'detail.recommend': 'مُستَحسَن',
  'detail.orders': 'مبيعات',
  'detail.quantity': 'كمية',
  'detail.pieces.available': 'متاح',
  'detail.delivery': 'توصيل',
  'detail.free.shipping': 'ًالشحن مجانا',
  'detail.estimated.delivery': 'التوصيل المتوقع',
  'detail.days': 'أيام',
  'detail.buy.now': 'يشتري',
  'detail.add.to.cart': 'أضف إلى السلة',
  'detail.buyer.protection': 'حماية المشتري',
  'detail.money.guarantee': 'ضمان استعادة الاموال',
  'detail.refund.desc': 'احصل على رد كامل إذا لم يتم وصف العنصر أو لم يتم تسليمه ',
  'detail.description': 'وصف المنتج',
  'detail.customer.reviews': 'آراء العملاء',
  'detail.specifications': 'مواصفات المنتج',
  'detail.top.selling.products': 'أفضل المنتجات مبيعًا ',
  'detail.recommended.for.you': 'موصى به لك',
  'detail.sold': 'مبيعات',
  'detail.receipt': 'تأكيد استلام',
  'detail.receipt.title': 'Cتأكيد استلام الطلب؟ ',
  'detail.receipt.content': 'بعد التأكيد ، يكتمل أمر المعاملة',
  'detail.comment': 'تعليق',
  'detail.refund.title': 'تأكيد طلب استرداد الأموال',
  'detail.refund.content': 'بعد التأكيد، سيتم تطبيق الطلب لاسترداد الأموال',
  'detail.sqtk': 'طلب استرداد الأموال',

  'confirm.shipping.address': 'عنوان المستلم',
  'confirm.change': 'يتغير',
  'confirm.payment.methods': 'طرق الدفع ',
  'confirm.summary': 'مستعمرة',
  'confirm.total.item.costs': 'مجموع الاشياء',
  'confirm.total': 'المجموع',
  'confirm.checkout': 'الدفع',
  'confirm.place.order': 'ضع طلبًا الآن ',
  'confirm.pay.now': 'ادفع الآن',
  'confirm.order.desc': 'بعد النقر على "تقديم الطلب" ، أؤكد أنني قرأت وأؤكد',
  'confirm.order.policy': 'جميع الشروط والسياسات ',
  'confirm.payment': 'يضمن التسوق العالمي أمان معلوماتك والدفع ',

  'address.title': 'عنوان المستلم',
  'address.default': 'تقصير',
  'address.edit': 'يحرر',
  'address.delete': 'يمسح',
  'address.new': 'أنشئ عنوانًا جديدًا ',
  'address.contact': 'اتصال',
  'address.address': 'عنوان',
  'address.phone': 'رقم التليفون',
  'address.set.default': 'الوضع الإفتراضي',
  'address.confirm': 'يتأكد',
  'address.cancel': 'يلغي',
  'address.del.title': 'هل تريد التأكيد لحذف العنوان؟ ',
  'address.del.content': 'هل أنت متأكد أنك تريد حذف عنوان الشحن هذا؟ ',

  'payment.method': 'طريقة الدفع او السداد',

  'shopping.title': 'عربة التسوق',
  'shopping.back.buy': 'العودة إلى التسوق الخاص ',
  'shopping.start.shopping': 'ابدأ التسوق ',

  'member.account': 'المركز الشخصي ',
  'member.overview': 'ملخص',
  'member.orders': 'طلبات',
  'member.payment': 'محفظتى',
  'member.address': 'عنوان الشحن',
  'member.wishlist': 'قائمة الرغبات',
  'member.followlist': 'قائمة المتابعة ',
  'member.message.center': 'مركز الرسائل',
  'member.setting': 'اعدادات شخصية',
  'member.shop.info': 'معلومات المتجر ',
  'member.shop.setting': 'إعدادات المتجر ',
  'member.order.notify': 'إخطار الطلب الجديد ',
  'member.order.tips': 'عن طريق إرسال بريد إلكتروني إلى البريد الإلكتروني لحساب التاجر ',

  'member.order.title': 'طلبي',
  'member.order.view.all': 'عرض الكل ',
  'member.order.all': 'الجميع',
  'member.order.unpaid': 'غير مدفوعة الأجر ',
  'member.order.paid': 'مدفوع',
  'member.order.procurement': 'المشتريات المعلقة ',
  'member.order.seller.paid': 'مشتراة ',
  'member.order.processing': 'قيد الانتظار',
  'member.order.shipped': 'شحنها',
  'member.order.completed': 'مكتمل',
  'member.order.refund': 'تم رد الأموال ',
  'member.order.all.time': 'الجميع',
  'member.order.empty': 'لا يوجد طلب حتى الآن ',
  'member.order.date': 'تاريخ',
  'member.order.purchase.date': 'شراء',
  'member.order.cpoy': 'ينسخ',
  'member.order.id': 'طلب',
  'member.order.detail': 'تفاصيل الطلب',
  'member.order.logistics': 'المعلومات اللوجستية',

  'member.payment.title': 'محفظتى',
  'member.wallet.balance': 'رصيد المحفظة ',
  'member.crypto.recharge': 'تعبئة رصيد',
  'member.crypto.withdrawal': 'ينسحب',
  'member.crypto.bank': 'بطاقة مصرفية',
  'member.wallet.record': 'سجل إيداع العملات ',
  'member.bankcard.record': 'سجل البطاقة المصرفية ',
  'member.withdrawal.record': 'سجل الانسحاب ',
  'member.income.record': 'سجل الدخل ',
  'member.transaction.record': 'سجل المعاملات',
  'member.wallet.freeze': 'تجميد الأموال',
  'member.wallet.profit': 'الأرباح المقدرة',

  'recharge.currency': 'عملة',
  'recharge.protocol': 'بروتوكول',
  'recharge.qrcode': 'رمز الاستجابة السريعة',
  'recharge.address': 'عنوان المحفظة ',
  'recharge.copy': 'نسخ العنوان ',
  'recharge.ok.text': 'تأكيد',
  'recharge.cancel.text': 'يلغي',

  'bank.recharge.title': 'إعادة شحن البطاقة المصرفية ',
  'bank.title': 'اسم البنك',
  'bank.name': 'اسم المستفيد',
  'bank.account': 'حساب استلام ',
  'bank.routing': 'رقم التحويل المصرفي',
  'bank.code': 'رمز بنكي',
  'bank.bankname': 'اسم البنك',
  'bank.bankaddress': 'عنوان البنك',
  'bank.ok.text': 'التالي',
  'bank.ok.prev': 'خطوة سابقة',
  'bank.submit': 'إرسال الآن ',
  'bank.amount': 'مبلغ إعادة الشحن ',
  'bank.amount.desc': 'الرجاء إدخال مبلغ إعادة الشحن ',
  'bank.type': 'يكتب',
  'bank.usd': 'دولار أمريكي',
  'bank.eur': 'اليورو',
  'bank.receipt.number': 'عدد إيصال',
  'bank.receipt.number.desc': 'الرجاء إدخال رقم إيصال المعاملة ',
  'bank.credential.picture': 'صورة الاعتماد ',
  'bank.credential.picture.desc': 'يرجى تحميل صورة الاعتماد ',
  'bank.remark': 'ملاحظة ',
  'bank.upload': 'تحميل الصورة',
  'bank.text.title': 'ملحوظات',
  'bank.text.t1': '1. لا تحتاج إلى أي ملاحظات لنقلها , اتركها فارغة ',
  'bank.text.t2': '2. بالنسبة للأسر المعيشية في هونغ كونغ ، حدد هونغ كونغ بدلاً من الصين',
  'bank.text.t3': '3. الحوالات الأمريكية ، في أيام الأسبوع ، تحويل الحوالات الأوروبية قبل الساعة 3:00 مساءً ، التحويل متاح خلال ساعات عمل البنك!',
  'bank.text.t4': '4. وقت الدفع هو T + 1 ، وآخر دفعة هي T + 3 في أوروبا',
  'bank.text.t5': '5. قم بإبلاغ المنصة قبل تحويل الأموال لتأكيد ما إذا كان الحساب متاحًا. إذا تم إغلاق الحساب ، فلن يتم دفع أي تعويض.',
  'bank.text.t6': '6. الإيداع عن طريق البطاقة المصرفية. تسود معلومات خدمة العملاء.',

  'bank.list.title': 'اسم البنك',
  'bank.list.code': 'رمز بنكي',
  'bank.list.amount': 'مبلغ التحويل ',
  'bank.list.number': 'عدد إيصال',
  'bank.list.img': 'صورة قسيمة ',
  'bank.list.status': 'حالة',
  'bank.list.time': 'وقت',
  'bank.list.status1': 'قيد المراجعة',
  'bank.list.status2': 'اجتاز',
  'bank.list.status3': 'مرفوض',

  'withdrawal.address.desc': 'الرجاء إدخال عنوان المحفظة! ',
  'withdrawal.number': 'رقم',
  'withdrawal.real.number': 'الوصول الفعلي',
  'withdrawal.number.desc': 'الرجاء إدخال مبلغ السحب! ',
  'withdrawal.btn.all': 'الجميع',
  'withdrawal.balance': 'توازن',
  'withdrawal.commission': 'عمولة',
  'withdrawal.actual.amount': 'حساب',
  'withdrawal.notice': 'يلاحظ',
  'withdrawal.notice.text': 'قبل التحويل ، يرجى التأكد من صحة معلومات عنوان الاستلام. بمجرد نقل الأصول ، لا يمكن إعادتها.',
  'withdrawal.notice.content': 'عمولة {name} ({cp_name}): القيمة السوقية الحالية {fee_rate}٪ / معاملة ، الحد الأدنى القياسي: {fee_min} {name} / معاملة',
  'withdrawal.submit': 'يُقدِّم',
  'withdrawal.choice': ' اختر عملة مشفرة ',
  'withdrawal.yzm': 'كود التحقق',
  'withdrawal.fs': 'إرسال',
  'withdrawal.qsryzm': 'الرجاء إدخال رمز التحقق من البريد الإلكتروني',

  'recive.method': 'بروتوكول',
  'recive.amount': 'المبلغ',
  'recive.address': 'عنوان',
  'recive.date': 'وقت',
  'recive.status': 'حالة',
  'recive.create.at': 'وقت المعاملة ',
  'recive.type': 'يكتب',
  'recive.befor': 'قبل المعاملة ',
  'recive.balance': 'توازن',
  'recive.freeze': 'تجميد ',
  'recive.review': 'قيد المراجعة',
  'recive.success': 'ناجح',
  'recive.reject ': ' مرفوض ',

  'advertise.title': 'مركز الترويج ',
  'advertise.shop.title': 'ترويج المتجر ',
  'advertise.shop.status': 'حالة',
  'advertise.shop.expired': 'منتهي الصلاحية',
  'advertise.shop.promotion': 'تعزيز',
  'advertise.shop.expire.date': 'تاريخ انتهاء الصلاحية',
  'advertise.shop.renew': 'تجديد الإعلان ',
  'advertise.shop.payable': 'المبلغ المستحق',
  'advertise.shop.explanation': 'شرح الإعلان ',
  'advertise.shop.text': 'شارك في الترويج الإعلاني للمنصة ، وزد من تعرض المتجر ، وقم بالترويج لأوامر المعاملات',
  'advertise.shop.paynow': 'التجديد على الفور ',
  'advertise.shop.modal.title': 'تأكيد الدفع',
  'advertise.shop.modal.desc': 'تم التأكيد على دفع رسوم الترقية ',
  'advertise.shop.modal.btn': 'تأكيد الدفع',

  'wishlist.title': 'قائمة الرغبات',
  'wishlist.delete': 'يمسح',
  'wishlist.orders': 'مبيعات',

  'followlist.title': 'قائمة المتابعة ',
  'followlist.delete': 'يمسح',
  'followlist.follow': 'يتبع',

  'store.dashboard': 'لوحة القيادة',
  'store.products': 'منتجات',
  'store.products.list': 'قائمة المنتجات ',
  'store.products.reviews': 'تعليقات المنتج',
  'store.orders': 'طلبات',
  'store.wallet': 'محفظة',
  'store.message': 'مركز الرسائل',
  'store.setting': 'إعدادات',
  'store.order.total.profit': 'إجمالي الدخل التشغيلي المقدر',

  'dashboard.store.hour.views': 'زيارات في الوقت الفعلي',
  'dashboard.store.today.views': 'مشاهدات اليوم',
  'dashboard.product.total': 'إجمالي المنتج',
  'dashboard.product.today': 'تمت إضافته اليوم ',
  'dashboard.order.total': 'الطلب الكلي',
  'dashboard.sales.total': 'حجم التداول المقدر',
  'dashboard.sales.real': 'مبيعات حقيقية',
  'dashboard.sales.pay': 'إجمالي المدفوعات',
  'dashboard.sales.profit': 'إجمالي الربح',
  'dashboard.commission.total': 'إجمالي الإيرادات',
  'dashboard.commission.today': 'أرباح اليوم',
  'dashboard.order.sales': 'مبيعات المنتجات ',
  'dashboard.sales.list': 'ترتيب المبيعات ',
  'dashboard.goods.cate.rate': 'نسبة فئة المنتج ',
  'dashboard.goods.wish': 'مثل ترتيب البضائع ',
  'dashboard.january': 'يناير',
  'dashboard.february': 'شهر فبراير',
  'dashboard.march': 'يمشي',
  'dashboard.april': 'أبريل',
  'dashboard.may': 'يمكن',
  'dashboard.june': 'يونيه',
  'dashboard.july': 'يوليو',
  'dashboard.august': 'أغسطس',
  'dashboard.september': 'سبتمبر',
  'dashboard.october': 'اكتوبر',
  'dashboard.november': 'شهر نوفمبر',
  'dashboard.december': 'ديسمبر',

  'products.add.new': 'إضافة منتج جديد ',
  'products.add.from.warehouse': 'إضافة منتجات من المستودع ',
  'products.delete': 'يمسح',
  'products.add': 'يضيف',
  'products.table.img': 'صورة',
  'products.table.name': 'اسم',
  'products.table.category': 'فئة',
  'products.table.wish': 'like',
  'products.table.stock': 'مخزون',
  'products.table.price': 'سعر',
  'products.table.profit': 'ربح',
  'products.table.action': 'فعل',
  'products.search.category': 'بحث حسب الفئة ',
  'products.back.product': 'رجوع إلى قائمة المنتجات ',
  'products.total': 'إجمالي المنتجات ',
  'products.yes': 'نعم',
  'products.no': 'يلغي',
  'products.batch.add': 'إضافة دفعة ',
  'products.ask.add': 'هل أنت متأكد أنك تريد إضافة منتجات؟ ',
  'products.batch.delete': 'حذف دفعة ',
  'products.ask.delete': 'هل أنت متأكد أنك تريد حذف المنتج؟ ',
  'products.top': 'قمة',
  'products.syzd': 'الصفحة الرئيسية أعلى',
  'products.zdwz': 'المركز الأول',
  'products.t1': 'ترقية',
  'products.t2': 'المفضل',
  'products.t3': 'بوتيك',
  'products.t4': 'لا شيء',

  'reviews.title': 'تعليقات المنتج',
  'reviews.product.name': 'منتج',
  'reviews.user.name': 'مستخدم',
  'reviews.star': 'تقييم',
  'reviews.comment': 'مراجعة المحتوى ',
  'reviews.sku': 'مواصفات المنتج',
  'reviews.imgs': 'معرض الصور',
  'reviews.created': 'تاريخ',

  'store.order.purchase': 'شراء الآن ',
  'store.order.purchase.desc': 'هل أنت متأكد أنك تريد الدفع مقابل هذا المنتج؟ ',
  'store.order.purchase.yes': 'ادفع على الفور',
  'store.order.purchase.no': 'يلغي',
  'store.order.desc': 'يلزم الدفع لشراء هذا الطلب ، وستحصل على مزايا بعد إتمام الطلب.',
  'store.order.no': 'رقم الأمر',
  'store.order.number': 'كمية',
  'store.order.buyer': 'مشتر',
  'store.order.total': 'المبلغ الإجمالي',
  'store.order.will.earning': 'كسب',
  'store.order.profit': 'ربح',
  'store.order.dividends': 'توزيعات الأرباح',
  'store.order.payment.status': 'حالة السداد',
  'store.order.seller.buy.status': 'حالة الشراء ',
  'store.order.status': 'حالة الطلب',
  'store.order.date': 'تاريخ الطلب',
  'store.order.purchase.date': 'تاريخ الشراء',
  'store.order.action': 'فعل',
  'store.order.purchase.amount': 'مبلغ الشراء',

  'income.create.at': 'وقت قياسي ',
  'income.order.sn': 'رقم الأمر',
  'income.realpay': 'كمية الطلب',
  'income.profit': 'دخل',

  'setting.avatar': 'الصورة الرمزية للأعمال ',
  'setting.upload': 'رفع',
  'setting.shop.name': 'اسم المحل',
  'setting.shop.phone': 'هاتف الاتصال',
  'setting.shop.address': 'عنوان المحل ',
  'setting.shop.save': 'يحفظ',
  'setting.upload.pic': 'تحميل الصورة',
  'setting.send.pic': 'إرسال صورة',

  'beseller.title': 'تقدم بطلب لتصبح تاجرًا ',
  'beseller.account': 'معلومات الحساب',
  'beseller.store': 'معلومات المتجر',
  'beseller.store.name': 'اسم المتجر ',
  'beseller.store.address': 'عنوان المتجر ',

  'store.home.title': 'إعدادات الصفحة الرئيسية ',
  'store.home.topimg': 'الصورة العلوية ',
  'store.home.banner': 'دائري ',
  'store.home.up3': 'ملاحظات: 3 تحميلات على الأقل ',
  'store.upload.more': 'تحميل صور متعددة ',
  'store.home.columns': 'أعمدة ',
  'store.home.bgimg': 'الصورة الخلفية',
  'store.goods.remark': 'ملاحظة: 5 عناصر في كل سطر ، بحد أقصى 10 عناصر لكل قسم',
  'store.home.select': 'حدد المنتج',
  'store.home.add': 'إضافة عمود ',

  //معلومات شخصية
  'setting.update.user': 'المعلومات الشخصية',
  'setting.update.info': 'تعديل المعلومات',
  'setting.user.avatar': 'الصورة الرمزية للمستخدم',
  'setting.user.nickname': 'لقب المستخدم',
  'setting.user.nickname.desc': 'الرجاء إدخال اسم المستخدم المستعار',

  'setting.safe.title': 'معلومات الأمان',
  'setting.user.passwd': 'تغيير كلمة المرور',
  'setting.passwd.title1': 'كلمة المرور القديمة',
  'setting.passwd.desc1': 'الرجاء إدخال كلمة المرور القديمة',
  'setting.passwd.title2': 'كلمة المرور الجديدة',
  'setting.passwd.desc2': 'الرجاء إدخال كلمة مرور جديدة',
  'setting.passwd.title3': 'تأكيد كلمة المرور',
  'setting.passwd.desc3': 'الرجاء إدخال كلمة مرور التأكيد',
  'setting.invitation': 'دعوة الأصدقاء',
  'setting.copy': 'نسخ',

  'adv.add.products': 'إضافة منتجات ترويجية',
  'adv.list.title': 'ترويج المنتج',
  'adv.type': 'نوع الترويج',
  'adv.begin.time': 'وقت الترويج',
  'adv.end.time': 'وقت الانتهاء',
  'adv.status': 'الحالة',
  'adv.status.s1': 'ترويج',
  'adv.status.s2': 'End',
  'adv.add.back': 'الرجوع إلى القائمة التي تمت ترقيتها',
  'adv.recharge': 'إعادة شحن نقاط الترويج',
  'adv.select.goods': 'تحديد البضائع',
  'adv.select.btn': 'المنتجات المروّجة',
  'adv.recharge.title': 'إعادة شحن نقاط الترويج',
  'adv.recharge.balance': 'رصيد النقاط',
  'adv.point': 'نقطة',
  'adv.point.rate': 'نسبة النقطة',
  'adv.recharge.amount': 'مبلغ الشحن',
  'adv.recharge.input.amount': 'أدخل مبلغ إعادة الشحن',
  'adv.select.mod': 'تحديد لوحة',
  'adv.mod1': 'الأعمدة الموصى بها',
  'adv.mod2': 'العمود المفضل',
  'adv.mod3': 'عمود البيع الأكثر شيوعًا',
  'adv.mod4': 'قائمة ممتازة',
  'adv.mod5': 'قائمة التصنيف',
  'adv.mod6': 'عرض ترويجي مفصل',
  'adv.ge': 'كمية',
  'adv.xs': 'ساعة',
  'adv.xzbk': 'تحديد لوحة',
  'adv.syme': 'الحصة المتبقية',
  'adv.tgsc': 'مدة العرض الترويجي',
  'adv.tgjg': 'سعر الترويج',
  'adv.syye': 'الرصيد الحالي',
  'adv.tgye': 'رصيد الترويج',
  'adv.yj1': 'متوقع',
  'adv.yj2': 'ابدأ الترويج',
  'adv.pay.point': 'نقاط الاستهلاك',

  'wallet.ykcsxf': 'تم خصم رسوم المناولة',
  'wallet.sxf': 'رسوم الخدمة',
  'wallet.zxje': 'الحد الأدنى للمبلغ',
  'wallet.24hxe': 'حد 24 ساعة',
  'wallet.desc.text': 'تحت أي ظرف من الظروف ، لن يطلب منك المسؤول تحويل الأموال إلى حساب ، ولن يطلب منك رمز التحقق. لا تشارك في أنشطة غير قانونية مثل الشراء نيابة عن الآخرين وغسيل الأموال وجمع الأموال غير القانوني ، واحذر من الاحتيال عبر الإنترنت ',
  'wallet.cunbi.text': 'يمكنك إيداع {name} فقط في هذا العنوان ، ولن يتم استرداد الأصول الأخرى',

  'credit.title': 'حد الائتمان',
  'credit.ed': 'الحصة',
  'credit.hk': 'السداد',
  'credit.kyye': 'الرصيد المتاح',
  'credit.dqqk': 'المتأخرات الحالية',
  'credit.hkje': 'مبلغ السداد',
  'credit.hkje.desc': 'الرجاء إدخال مبلغ السداد',
  'credit.cgje': 'مبلغ الشراء',
  'credit.qrz': 'شهادة',
  'credit.srxyj': 'أدخل مبلغ الدفعة الائتمانية',

  'store.gzl': 'متابعة',
  'store.wxz': 'غير محدود',

  'auth.smrz': 'مصادقة الاسم الحقيقي',
  'auth.rzlx': 'يكتب',
  'auth.sfzm': 'واجهة بطاقة الهوية',
  'auth.sffm': 'الجانب الآخر من بطاقة الهوية',
  'auth.zsxm': 'الاسم الحقيقي',
  'auth.zjhm': 'رقم المستند',
  'auth.yyzz': 'رخصة تجارية',
  'auth.gsmc': 'اسم الشركة',
  'auth.shz': 'تدقيق',
  'auth.ytg': 'ناجح',
  'auth.ybh': 'مرفوض',
  'auth.zt': 'ولاية',
  'auth.gr': 'شخصي',
  'auth.gs': 'مَشرُوع',
  'auth.ljtj': 'التقدم بطلب للمصادقة',
  'auth.wrz': 'غير مصدق',

  'credit.qts': 'عرض الوصف',
  'store.spss': 'الحد الأعلى للسلعة',
  'store.dpfh': 'تخزين الأرباح',
  'store.qydj': 'مستوى حقوق الملكية',
  'store.level': 'level',
  'store.jian': 'قطع',

  'order.notify.email': 'نظرًا للتحكم في مخاطر البريد الإلكتروني ، يتم إرسال رسائل البريد الإلكتروني للتذكير بأمر النظام الأساسي بشكل متكرر وسيتم اعتراضها. من أجل استلامها في الوقت المناسب ، يوصى بإضافة {email} إلى تسجيل الدخول القائمة البيضاء للبريد الإلكتروني ',

  'setting.sjxx': 'معلومات العمل',
  'setting.smrz': 'مصادقة الاسم الحقيقي',
  'setting.aqzx': 'مركز الأمن',
  'setting.tzxx': 'معلومات الإعلام',
  'setting.dpzx': 'تزيين الصفحة الرئيسية',
  'setting.sjqy': 'المصالح التجارية',
  'setting.sjdjqy': 'وصف مزايا مستوى التاجر',

  'setting.fhbky': 'العائد الحالي غير متوفر',
  'setting.spgzyzy': 'قواعد وإرشادات المتجر',

  'desc.dqqy': 'حقوق الملكية الحالية',
  'desc.xjqy': 'المصالح التابعة',
  'desc.sjqy_0': 'حد ائتمان المتجر',
  'desc.sjqy_1': 'توزيع أرباح المتجر',
  'desc.sjqy_2': 'عدد المنتجات التي يمكن نشرها',

  'share.yqlb': 'قائمة الدعوة',
  'share.zmj': 'إجمالي البائع',
  'share.zdd': 'الترتيب الإجمالي',
  'share.zsy': 'إجمالي الدخل',
  'share.wcl': 'غير معالج',
  'share.ycl': 'معالجة',
  'share.ywc': 'مكتمل',
  'share.dpmc': 'اسم المتجر',
  'share.ddl': 'كمية الطلب',
  'share.jjsy': 'دخل السمسرة',
  'share.zts': 'إجمالي عدد العناصر',

  'chat.state': 'حالة',
  'chat.zx': 'عبر الإنترنت',
  'chat.ljz': 'جارٍ الاتصال',

  'bind.wallet.title': 'عنوان السحب',
  'bind.address.title': 'إضافة عنوان',
  'bind.bj': 'تحرير',
  'bind.sc': 'حذف',
  'bind.qrsc': 'هل أنت متأكد أنك تريد حذف هذا العنوان؟',
  'bind.qbdz': 'عنوان المحفظة',
  'bind.tjsj': 'أضف الوقت',
  'bind.cz': 'عملية',
  'pull.address.choice': 'الرجاء اختيار عنوان السحب!',

  'order.sxsj': 'الوقت الفعلي',
  'order.ljsj': 'ساري المفعول على الفور',
  'order.dssj': 'توقيت فعال',

  'store.st.zt': 'حالة المتجر',
  'store.st.zc': 'عادي',
  'store.st.zc.desc': 'متجرك عادي , يرجى الاستمرار فيه',
  'store.st.yc': 'استثناء',
  'store.st.yc.desc': 'يحتوي متجرك على بعض الطلبات غير الطبيعية أو تلقي شكاوى من العملاء',
  'store.st.dj': 'تجميد',
  'store.st.dj.desc': 'متجرك به مخاطر تشغيل معينة والأموال مجمدة',
  'store.st.jy': 'معطل',
  'store.st.jy.desc': 'لقد انتهك متجرك الاتفاقيات والقواعد المتعلقة بالمنصة وتم تعطيله',

  'task.sy': 'الصفحة الرئيسية',
  'task.rwlbo': 'قائمة المهام',
  'task.rwxqo': 'تفاصيل المهمة',
  'task.ljcy': 'شارك الآن',
  'task.gdrw': 'مزيد من المهام',
  'Task.rwlb': ' قائمة مهام الاستيلاء',
  'task.qbrw': 'كافة المهام',
  'task.wcyd': 'شاركت في',
  'Task.qdz': ' الحصول على الطلبات',
  'task.ywc': 'مكتمل',
  'task.djy': 'يتم تداولها',
  'task.ddje': 'Order Amount',
  'task.cyrs': 'عدد المشاركين',
  'task.ewsy': 'مزايا إضافية',
  'task.jzsj': 'الموعد النهائي',
  'task.ckxq': 'عرض التفاصيل',
  'task.rwxq': 'تفاصيل المهمة',
  'task.cylb': 'قائمة المشاركة',
  'task.qdcyrw': 'هل أنت متأكد أنك تريد المشاركة في مهمة الترتيب هذه؟',
  'task.sd': 'نعم',
  'task.bue': 'لا',

  'task.wdsy': 'الإيرادات',
  'task.wdfh': 'توزيعات الأرباح',
  'task.wdqd': 'الحصول على طلب',
  'task.dd.leixin': 'نوع الطلب',
  'task.dd.qdrw': 'مهمة ترتيب سريع',
  'task.dd.ptdd': 'أمر عادي',

  'flow.lltg': 'ترويج التدفق',
  'flow.gmll': 'شراء حزمة البيانات',
  'flow.dqtc': 'الحزمة الحالية',
  'flow.tc1': 'Package',
  'flow.sy2': 'المتبقي',
  'flow.tian3': 'يوم',
  'flow.jlzs': 'العدد الإجمالي للسجلات',
  'flow.lltc': 'حزمة التدفق',
  'flow.ll': 'flow',
  'flow.gmsj': 'وقت الشراء',
  'flow.tcjg': 'سعر الحزمة',
  'flow.sjzf': 'الدفع الفعلي',
  'flow.tcsm': 'وصف الحزمة',
  'flow.tcsm.sm': ' سيؤدي شراء هذه الحزمة إلى زيادة تعرض المتجر واكتساب المزيد من حركة مرور العملاء. من أجل الحصول على المزيد من الطلبات وزيادة إيرادات المتجر. ',

  'flow.tcmc': 'اسم الحزمة',
  'flow.gmsc': 'مدة الشراء',
  'flow.zje': 'المبلغ الإجمالي',
  'flow.jzsj': 'الموعد النهائي',
  'flow.gmrq': 'تاريخ الشراء',
  'flow.tian1': 'يوم',
  'flow.zhou1': 'أسبوع',
  'flow.yue1': 'شهر',

  'share.dj': 'مستوى',

  'share.hz': 'نقل',
  'share.hzd': 'نقل إلى',
  'share.qbye': 'رصيد المحفظة',
  'share.tgje': 'مبلغ الترويج',
  'share.hzje': 'مبلغ التحويل',
  'share.qsrhzje': 'الرجاء إدخال مبلغ التحويل',

  '_inv._title': 'وصف الدعوة',
  '_inv._t1': '1. دعوة وعلاقة مدعوة',
  '_inv._desc1': 'A يدعو B ، B يدعو C ، C يدعو D. أعلى مستوى هو 4 ، إذا دعا D E مرة أخرى ، فلا علاقة لـ E بـ A. ',
  '_inv._t2': '2. يحصل الداعي على مكافأة',
  '_inv._desc2': 'A يحصل على 4٪ من إجمالي المبلغ المكتمل حسب الأمر B ، يحصل A على 2٪ من إجمالي المبلغ المكتمل بالأمر C ، ويحصل A على 1٪ من إجمالي المبلغ المكتمل بواسطة الأمر D',

  '_index._gm': 'شراء',
  '_index._sm': 'الوصف: زيادة عرض المتجر وجذب حركة المرور',

  '_n.dlyzc': 'تسجيل الدخول والتسجيل',
  '_n.hy4': 'هناك 4 آخرين',
  '_n.ckgd': 'شاهد المزيد',
  '_n._cxhzk': 'العروض الترويجية والخصومات',
  '_n._cwyp': 'مستلزمات الحيوانات الأليفة',
  '_n._tjsp': 'عرض خاص',
  '_n._rmfl': 'الفئات الشائعة',
  '_n._tjsp2': 'المنتجات الموصى بها',
  '_n._rxsp': 'المنتجات الموصى بها',
  '_n._rmsp': 'العناصر الشائعة',
  '_n._gdsp': 'المزيد من المنتجات',
  '_n._yzsj': 'تاجر عالي الجودة',

  '_n._zxsc': 'مركز التسوق عبر الإنترنت',
  '_n._fkyys': 'الدفع والشحن',
  '_n._xsgz': 'قواعد المبيعات',
  '_n._hhjth': 'عمليات التبادل والإرجاع',
  '_n._lxwm': 'اتصل بنا',
  '_n._sczc': 'سياسة المركز التجاري',
  '_n._tkytj': 'الشروط والأحكام',
  '_n._tuzc': 'سياسة الإرجاع',
  '_n._zczc': 'سياسة الدعم',
  '_n._yszc': 'سياسة الخصوصية',
  '_n._scjj': 'مقدمة عن المركز التجاري',
  '_n._ljgm': 'اشتر الآن',

  '_n2._rxsp': 'المنتجات الأكثر مبيعًا',
  '_n2._pl': 'تعليقات',
  '_n2._sjxx': 'معلومات العمل',
  '_n2._rhsh': 'كيفية استلام البضائع',
  '_n2._xsxd': 'الطلب عبر الإنترنت',
  '_n2._jscldd': 'خدمة عملاء مخصصة',
  '_n2._mfps': 'شحن مجاني',
  '_n2._2xsfh': 'تسليم سريع',
  '_n2._1d5tdd': 'الوصول خلال 1-5 أيام',
  '_n2._tkzd': 'البريد السريع',
  '_n2._ckfh': 'الشحن من المستودع',
  '_n2._zcps': 'مستودع قريب',
  '_n2._jsd': 'نقطة الاستلام',
  '_n2._qsjps': 'التسليم في جميع أنحاء العالم',
  '_n2._thtj': 'شروط الإرجاع',
  '_n2._thtjsm': 'يمكنك استبدال المنتج أو إرجاعه خلال 14 يومًا من تاريخ الشراء',
  '_n2._gycp': 'حول المنتج',
  '_n2._yc': 'مخفي',
  '_n2._tiao': 'مقالة',
  '_n2._sypl': 'جميع التعليقات',
  '_n2._lxfk': 'اترك تعليقات',
  '_n2._xzspgg': 'الرجاء تحديد مواصفات المنتج',
  '_n2._qxdl': 'الرجاء تسجيل الدخول أولاً',
  '_n2._rnsj': 'كما ترى',
  '_n2._rx': 'تخفيضات كبيرة',

  '_n3._qrdd': 'تأكيد الطلب',
  '_n3._njsp': '{num} عنصر',
  '_n3._ddzf': 'دفع الطلب',
  '_n3._wydlsp': 'مرحبًا بك في تسجيل الدخول إلى المتجر',

  '_n3._sjwfgm': 'لا يستطيع التاجر شراء البضائع',
  '_n3._sjbnlt': 'لا يمكن للشركات الدردشة',
  '_chat._hc': 'انسحاب',

  '_zc._sryxdz': 'الرجاء إدخال بريدك الإلكتروني',
  '_zc._yxgscw': 'خطأ في تنسيق البريد الإلكتروني',
  '_zc._qsrmm': 'الرجاء إدخال كلمة المرور',
  '_zc._lcmmbxd': 'كلمتا مرور غير متناسقتين',
  '_zc._hdyzwtg': 'يُرجى التحقق عبر شريط التمرير',
  '_zc._qsryqm': 'الرجاء إدخال رمز الدعوة',
  '_zc._yhzh': 'تسجيل حساب المستخدم',
  '_zc._qzc': 'اذهب إلى التسجيل',
  '_zc._srdpm': 'الرجاء إدخال اسم المتجر',

  '_zc._gszz': 'مؤهلات الشركة',
  '_zc._ptzz': 'المؤهلات المتعلقة بالمنصة',

  '_dd._tijd': 'إرسال الطلب',
  '_dd._xzdz': 'الرجاء تحديد عنوان التسليم',
  '_dd._zfz': 'جاري الدفع',

  '_wd._zf': 'الدفع',
  '_wd._cz': 'إعادة الشحن',
  '_wd._txsq': 'طلب السحب',
  '_wd._ddsy': 'دخل الطلب',
  '_wd._cgzf': 'شراء',
  '_wd._txbh': 'رفض',
  '_wd._txtg': 'تم النجاح',
  '_wd._ddtk': 'استرداد الطلب',
  '_wd._yehz': 'نقل',
  '_wd._gmll': 'شراء حركة المرور',
  '_wd._zjkc': 'خصم الإيجار',
  '_wd._fk': 'جيد',
  '_wd._ns': 'ضريبة',
  '_wd._bzj': 'الهامش',

  '_xxtz._zntz': 'إشعار بالموقع',
  '_xxtz._fh': 'رجوع',
  '_xxtz._jzgd': 'تحميل المزيد',
  '_xxtz._mysj': 'لا مزيد',

  '_sjsy._jrdd': 'طلب اليوم',
  '_sjsy._rxse': 'المبيعات اليومية',

  '_sjsy._xh': 'الفهرس',

  '_wd._nbjy': 'نقل داخلي',
  '_wd._qsruid': 'الرجاء إدخال المعرف الفريد للمدفوع لأمره',
  '_wd._je': 'المبلغ',
  '_wd._qsrhzje': 'الرجاء إدخال مبلغ التحويل',
  '_wd._skr': 'المدفوع لأمره',
  '_wd._nbhzsm': 'للتحويل داخليًا إلى مستخدمي المنصة، يرجى التحقق من معلومات المستفيد بعناية',

  '_st._spxlphb': 'تصنيف مبيعات المنتج',
  '_st._czsp': 'إعادة ضبط البحث',

  '_st._pljrgwc': 'الانضمام للكل',

  '_sj._yxz': 'محدد',
  '_sj._hjzje': 'إجمالي المبلغ',
  '_sj._zdcr': 'الإيداع تلقائيًا',

  '_wd._ddfh': 'أرباح',

  '_dd._csqx': 'إلغاء الطلب',

  '_tx._yhktx': 'السحب البنكي',
  '_tx._sryhmc': 'الرجاء إدخال اسم البنك',
  '_tx._srskrmc': 'الرجاء إدخال اسم المدفوع لأمره',
  '_tx._srskzh': 'الرجاء إدخال رقم حساب الدفع',
  '_tx._sryhluh': 'الرجاء إدخال رقم توجيه البنك',
  '_tx._sryhdm': 'الرجاء إدخال رمز البنك',
  '_tx._sryhdz': 'الرجاء إدخال عنوان البنك',
  '_tx._txje': 'مبلغ السحب',
  '_tx._srtxje': 'الرجاء إدخال مبلغ السحب',
  '_tx._txkc': 'رسوم السحب {_sxf}%',

  '_yye._yyye': 'المبيعات الشهرية',
  '_yye._ysy': 'الدخل الشهري',

  '_sj._hjzsy': 'إجمالي الدخل الإجمالي',

  '_yhdl._dl': 'تسجيل الدخول',
  '_yhdl._zc': 'إنشاء حساب',

  '_zdy._24zrkf': 'نحن هنا لمساعدتك على مدار الساعة طوال أيام الأسبوع',
  '_zdy._zhsz': 'إعدادات الحساب',
  '_zdy._mfps': 'لفترة محدودة, يمكنك الحصول على شحن مجاني خلال يومي عمل لآلاف العناصر في مناطق محددة. ',
  '_zdy._gmsp': 'تسوق منتجات جديدة',
  '_zdy._czyh': 'عروض رائعة, استمتع بمنتجاتك المفضلة',
  '_zdy._xsgw': 'التسوق لفترة محدودة! اطلب قبل 12 مايو عند الظهر بالتوقيت الشرقي للشحن المجاني. يتم تسليمها إلى باب منزلك بواسطة سيارة مخصصة! ',
  '_zdy._ssgjc': 'أدخل كلمة البحث الرئيسية',

  '_zdy._rmjx': 'أفضل اختياراتك',

  '_dd._plcz': 'عملية مجمعة',
  '_dd._plcg': 'الشراء بالجملة',
  '_dd._qgxcgd': 'الرجاء التحقق من الطلب الذي تحتاج إلى شرائه',
  '_dd._yxddbcg': 'الطلب المحدد لا يتطلب الشراء',
  '_dd._qdyplcgm': 'هل أنت متأكد من رغبتك في شراء الطلب المحدد بكميات كبيرة؟ ,الكمية: {n}',
  '_dd._qdcg': 'تأكيد الشراء',
  '_dd._qx': 'إلغاء',
  '_dd._plcgwc': 'اكتملت عملية الشراء المجمعة، بنجاح: {s}/{t}',
  '_dd._zzcgz': 'شراء',

  '_st._czhk': 'إعادة الشحن والسداد',
  '_st._dkje': 'مبلغ القرض',
  '_st._dkjl': 'تاريخ القرض',
  '_st._hkfs': 'السداد',
  '_st._hkje': 'مبلغ السداد',
  '_st._jrong': 'تمويل مول',
  '_st._ljsq': 'تقدم بطلبك على الفور',
  '_st._qrshkje': 'الرجاء إدخال مبلغ السداد',
  '_st._qsrdkje': 'الرجاء إدخال مبلغ القرض',
  '_st._shz': 'قيد المراجعة',
  '_st._sqdklx': 'يُقرض',
  '_st._sqhk': 'التقدم بطلب السداد',
  '_st._sqhklx': 'السداد',
  '_st._sqjk': 'التقدم بطلب للحصول على قرض',
  '_st._sqlx': 'يكتب',
  '_st._ybh': 'يرفض',
  '_st._yjk': 'اقترضت، استعارت',
  '_st._ytg': 'يمر',
  '_st._zzhk': 'في انتظار السداد',

  '_st._dqed': 'مبلغ القرض',
  '_st._ccdked': 'لا يمكن تجاوز حد القرض',
  '_st._tjsqz': 'جاري تقديم الطلب',
  '_st._dklx': 'اهتمام',
  '_st._dkll': 'سعر فائدة القرض:{n}% ',
  '_kdts._ts': 'مفتوح لمدة {t} من الأيام',
  '_jltg._tgje': 'الاستثمار الإعلاني',

  '_st._yqk': 'المتأخرات',
  '_st._qb': 'الكل',

  '_spxx._spbm': 'كود',

  '_scrz._yyzz': 'رخصة تجارية'
}
