export default {
  'nav.account': 'Centre personnel',
  'nav.language': 'Chinois simplifié',
  'signup.success': 'Soumettre avec succès',

  //erreur
  'contact.empty': 'Les informations de contact ne peuvent pas être vides',
  'phone.country.empty': 'Le préfixe téléphonique ne peut pas être vide',
  'phone.empty': 'Le téléphone ne peut pas être vide',
  'email.valid': 'Veuillez entrer le bon e-mail',

  // base
  'base.success': 'succès',
  'base.save': 'Enregistrer',
  'base.slide': 'Veuillez tirer sur le curseur pour vérifier',
  'base.send.code': 'Envoyer le code de vérification',
  'base.send.time': '({time}) renvoyer le code de vérification',
  'base.send.success': 'Envoi réussi',
  'base.view.more': 'Voir plus',
  'base.Language': 'Langue',
  'base.previous': 'Page précédente',
  'base.next': 'Page suivante',
  'base.total.page': 'Total {page} pages',
  'base.go.to.page': 'Aller à la page',
  'base.go': 'aller à',
  'base.followers': 'Abonnés',
  'base.follow': 'suivre',
  'base.following': 'Suivi',
  'base.visit.store': 'Parcourir la boutique',
  'base.contact': 'Contactez-nous',
  'base.delivery': 'Délai de livraison estimé: 1-7 jours',
  'base.search': 'Rechercher',
  'base.search.text': 'Entrez la recherche par mot-clé',
  'base.server': 'Service client en ligne',

  //indice
  'text.categories.title': 'Catégorie de produit',
  'text.welcome': 'Bienvenue dans le shopping!',
  'text.wishlist': "J'aime la liste",
  'text.account': 'Centre personnel',
  'text.orders': 'Commandes',
  'text.messages': 'Messages',
  'text.suggest.title': 'Offre exclusive',
  'text.suggest.content': 'Uniquement pour les nouveaux membres premium internationaux !',
  'text.super.deals': 'Top produits, prix incroyables.',

  //s'inscrire
  'signup.register': "S'inscrire",
  'signup.signin': 'Connexion',
  'signup.store.signin': 'Connexion professionnelle',
  'signup.sign.out': 'Déconnexion',
  'signup.email': 'Adresse e-mail',
  'signup.password': 'Mot de passe',
  'signup.qr.password': 'Confirmer le mot de passe',
  'signup.confirm.password': 'Confirmer le mot de passe',
  'signup.forgot.password': 'Mot de passe oublié',
  'signup.invitation': "Code d'invitation",
  'signup.char': 'Personnage',
  'signup.contains': 'Contient des chiffres, des lettres ou des symboles',
  'signup.qr.contains': 'Les deux mots de passe sont incohérents',
  'signup.create.account': 'Créer un compte',
  'signup.agree': 'Créez un compte, vous acceptez',
  'signup.member.agreement': "Accord d'adhésion",
  'inscription.et': 'et',
  'signup.privacy.policy': 'Politique de confidentialité',
  'signup.email.code': "Vérification de l'e-mail",
  'signup.last.step': 'Dernière étape',
  'signup.send.email': 'Veuillez entrer le code à 4 chiffres envoyé à {email}',
  'signup.modify.email': "Modifier l'e-mail",
  'signup.verify.email': "Vérifier l'e-mail",
  'signup.have.store': 'Avoir un compte marchand',
  'signup.goto.signin': 'Connexion professionnelle',
  'signup.no.store': 'Pas de compte marchand',
  'signup.goto.store': 'Inscrire un marchand',
  'signup.next': 'Suivant',
  'signup.your.email': 'votre e-mail',
  'signup.code.text': 'Code de vérification',
  'signup.submit.signup': 'Inscrivez-vous maintenant',
  'signup.smrz': 'Authentification par nom réel',
  'signup.derb': 'Deuxième étape',
  'signup.qsrxm': 'Veuillez entrer le nom du document',
  'signup.qsrhm': "Veuillez entrer le numéro d'identification",

  //oublié
  'forgot.title': 'Réinitialiser le mot de passe',
  'forgot.btn.check': "Vérifier l'e-mail",
  'forgot.reset.now': 'Réinitialiser maintenant',

  //magasin
  'store.info.open': 'Ouvert {y}semaines',

  // bas de page
  'footer.great.value': 'Excellent rapport qualité/prix',
  'footer.great.value.desc': "Nous offrons des prix compétitifs pour plus de 100 millions d'articles.",
  'footer.shopping': 'Shopping mondial',
  'footer.shopping.desc': 'Nous livrons dans plus de 200 pays et régions, et notre site Web est disponible en 7 langues.',
  'footer.safe.payment': 'Paiement sécurisé',
  'footer.safe.payment.desc': 'Payez avec les méthodes de paiement les plus populaires et les plus sûres au monde.',
  'footer.shop.with.confidence': 'Achetez en toute confiance',
  'footer.shop.with.confidence.desc': "Notre politique de protection des acheteurs couvre l'ensemble de votre processus d'achat.",
  'footer.help.center': "Centre d'aide",
  'footer.help.center.desc': "Assistance tout temps pour créer une expérience d'achat fluide.",
  'footer.terms.conditions': "Conditions d'utilisation",
  'footer.return.policy': 'Politique de retour',
  'footer.support.policy': "Politique d'assistance",
  'footer.privacy.policy': 'Politique de confidentialité',
  'footer.be.seller': 'Devenir vendeur',
  'footer.apply.now': 'Postuler maintenant',
  'footer.stay.connected': 'Rester connecté',

  'footer.about.us': 'À propos de nous',
  'footer.about.company': "Profil de l'entreprise",
  'footer.about.video': 'Présentation de la vidéo',
  'footer.contact': 'Coordonnées',

  'footer.my.account': 'Mon compte',
  'footer.my.logout': 'Déconnexion',
  'footer.ma.commande': 'Historique des commandes',
  'footer.my.wish': 'Ma liste de souhaits',
  'footer.my.join': 'Devenir membre partenaire',
  'footer.email': 'E-mail',
  'footer.gfemail': 'Courriel officiel',
  'footer.fwemail': 'Courriel de service',
  'footer.address': 'Adresse',

  'apply.success': 'Candidature réussie',
  'apply.success.desc': 'Appliquée avec succès, accédez au compte de connexion',

  // catégories
  'category.title': 'Catégories associées',
  'category.all': 'Toutes les catégories',

  //détail
  'detail.store.home': 'Accueil magasin',
  'detail.sale.items': 'Liste de produits',
  'detail.recommend': 'Recommander',
  'detail.orders': 'Ventes',
  'detail.quantity': 'Quantité',
  'detail.pieces.available': 'Disponible',
  'detail.delivery': 'Livraison',
  'detail.free.shipping': 'Livraison gratuite',
  'detail.estimated.delivery': 'Arrivée estimée',
  'detail.days': 'jours',
  'detail.buy.now': 'Acheter',
  'detail.add.to.cart': 'Ajouter au panier',
  'detail.buyer.protection': "Protection de l'Acheteur",
  'detail.money.guarantee': 'Garantie de remboursement',
  'detail.refund.desc': "Obtenez un remboursement complet si l'article ne correspond pas à la description ou n'est pas livré",
  'detail.description': 'Description du produit',
  'detail.customer.reviews': 'Avis clients',
  'detail.specifications': 'Spécifications du produit',
  'detail.top.selling.products': 'Produits les plus vendus',
  'detail.recommended.for.you': 'Recommandé pour vous',
  'detail.vendu': 'Ventes',
  'detail.receipt': 'Confirmer la réception',
  'detail.receipt.title': 'Confirmer la réception de la commande? ',
  'detail.receipt.content': 'Après confirmation, la commande de la transaction est terminée',
  'detail.comment': 'Commentaire',
  'detail.refund.title': 'Confirmer la demande de remboursement',
  'detail.refund.content': 'Après confirmation, la commande demandera un remboursement',
  'detail.sqtk': 'Demander un remboursement',

  //confirmer
  'confirm.shipping.address': 'Adresse du destinataire',
  'confirmer.change': 'Modifier',
  'confirm.payment.methods': 'Modes de paiement',
  'confirm.summary': 'Règlement',
  'confirm.total.item.costs': 'Total des articles',
  'confirmer.total': 'Total',
  'confirm.checkout': 'Passer à la caisse',
  'confirm.place.order': 'Passez une commande maintenant',
  'confirm.pay.now': 'Payer maintenant',
  'confirm.order.desc': "Après avoir cliqué sur 'Passer commande', je confirme avoir lu et confirmé",
  'confirm.order.policy': 'Tous les termes et politiques',
  'confirm.payment': 'Global Shopping assure la sécurité de vos informations et de vos paiements',

  // adresse
  'address.title': 'Adresse de réception',
  'adresse.default': 'par défaut',
  'adresse.edit': 'Modifier',
  'adresse.delete': 'Supprimer',
  'address.new': 'Créer une nouvelle adresse',
  'adresse.contact': 'Contact',
  'adresse.adresse': 'Adresse',
  'adresse.téléphone': 'Numéro de téléphone',
  'address.set.default': 'Définir par défaut',
  'adresse.confirmer': 'Confirmer',
  'adresse.annuler': 'Annuler',
  'address.del.title': "Confirmer pour supprimer l'adresse?",
  'address.del.content': 'Êtes-vous sûr de vouloir supprimer cette adresse de livraison?',

  'payment.method': 'Mode de paiement',

  // Panier
  'shopping.title': 'Panier',
  'shopping.back.buy': 'Retour aux achats spéciaux',
  'shopping.start.shopping': 'Commencez vos achats',

  // membre
  'member.account': 'Centre personnel',
  'member.overview': "Vue d'ensemble",
  'member.orders': 'Commandes',
  'member.payment': 'Mon portefeuille',
  'member.address': 'Adresse de réception',
  'member.wishlist': "J'aime la liste",
  'member.followlist': 'Suivre la liste',
  'member.message.center': 'Centre de messagerie',
  'member.setting': 'Paramètres personnels',
  'member.shop.info': 'Informations sur la boutique',
  'member.shop.setting': 'Paramètres de la boutique',
  'member.order.notify': 'Notification de nouvelle commande',
  'member.order.tips': "En envoyant des e-mails à l'e-mail du compte marchand",

  // membre.commande
  'member.order.title': 'Ma commande',
  'member.order.view.all': 'Voir tout',
  'member.order.all': 'Tous',
  'member.order.unpaid': 'non payé',
  'member.order.paid': 'Payé',
  'member.order.procurement': 'à acheter',
  'member.order.seller.paid': 'Acheté',
  'member.order.processing': 'à traiter',
  'member.order.shipped': 'expédié',
  'member.order.completed': 'Terminé',
  'member.order.refund': 'Remboursé',
  'member.order.all.time': 'tous',
  'member.order.empty': 'Pas encore de commande',
  'member.order.date': 'date',
  'member.order.purchase.date': 'Achat',
  'member.order.cpoy': 'Copier',
  'member.order.id': 'Commande',
  'member.order.detail': 'Détails de la commande',
  'member.order.logistics': 'Informations logistiques',

  // membre. paiement
  'member.payment.title': 'Mon portefeuille',
  'member.wallet.balance': 'solde du portefeuille',
  'membre.crypto.recharge': 'Recharger',
  'member.crypto.withdrawal': 'Retrait',
  'member.crypto.bank': 'Carte bancaire',
  'member.wallet.record': 'enregistrement de dépôt de pièces',
  'member.bankcard.record': 'Relevé de carte bancaire',
  'member.withdrawal.record': 'Enregistrement de retrait',
  'membre.revenu.record': 'record de revenu',
  'member.transaction.record': 'Enregistrement de la transaction',
  'member.wallet.freeze': 'Bloquer les fonds',
  'member.wallet.profit': 'Revenus estimés',

  //recharger
  'recharge.currency': 'Devise',
  'recharge.protocole': 'Protocole',
  'recharge.qrcode': 'code QR',
  'recharge.address': 'adresse du portefeuille',
  'recharge.copy': "Copier l'adresse",
  'recharge.ok.text': 'Confirmer',
  'recharge.cancel.text': 'Annuler',

  //banque
  'bank.recharge.title': 'Recharge carte bancaire',
  'bank.title': 'Nom de la banque',
  'bank.name': 'Nom du bénéficiaire',
  'bank.account': 'Compte destinataire',
  'bank.routing': "Numéro d'acheminement bancaire",
  'bank.code': 'Code banque',
  'bank.bankname': 'Nom de la banque',
  'bank.bankaddress': 'Adresse de la banque',
  'bank.ok.text': 'Suivant',
  'bank.ok.prev': 'Étape précédente',
  'bank.submit': 'Soumettre maintenant',
  'bank.amount': 'Montant de la recharge',
  'bank.amount.desc': 'Veuillez entrer le montant de la recharge',
  'bank.type': 'type',
  'bank.usd': 'USD',
  'bank.eur': 'Euro',
  'bank.receipt.number': 'Numéro de reçu',
  'bank.receipt.number.desc': 'Veuillez entrer le numéro de reçu de transaction',
  'bank.credential.picture': "Photo d'identification",
  'bank.credential.picture.desc': "Veuillez télécharger l'image d'identification",
  'bank.remark': 'Remarque',
  'bank.upload': 'Télécharger des images',
  'bank.text.title': 'Notes',
  'bank.text.t1': "1. Vous n'avez pas besoin de remarques pour le virement, laissez ce champ vide",
  'bank.text.t2': "2. Sélectionnez Hong Kong comme pays du ménage de Hong Kong, n'ajoutez pas la Chine",
  'bank.text.t3': "3. Les transferts de fonds aux États-Unis, en semaine, les transferts de fonds vers l'Europe avant 15h00, les transferts de fonds sont disponibles pendant les heures d'ouverture de la banque!",
  'bank.text.t4': '4. Le délai de paiement est T+1, et le dernier paiement est T+3 en Europe',
  'bank.text.t5': "5. Informez la plateforme avant de transférer de l'argent pour confirmer si le compte est disponible. Si le compte est fermé, aucune indemnité ne sera versée.",
  'bank.text.t6': '6. Dépôt par carte bancaire, toutes les informations du service client prévaudront.',

  //liste de banque
  'bank.list.title': 'Nom de la banque',
  'bank.list.code': 'Code banque',
  'bank.list.amount': 'Montant du transfert',
  'bank.list.number': 'Numéro de reçu',
  'bank.list.img': 'Image du bon',
  'bank.list.status': 'Statut',
  'bank.list.time': 'heure',
  'bank.list.status1': "En cours d'examen",
  'bank.list.status2': 'Réussi',
  'bank.list.status3': 'Refusé',

  // Retrait
  'withdrawal.address.desc': "Veuillez saisir l'adresse du portefeuille!",
  'retrait.numéro': 'Numéro',
  'withdrawal.real.number': 'arrivée réelle',
  'withdrawal.number.desc': 'Veuillez saisir le montant du retrait !',
  'retrait.btn.all': 'tous',
  'retrait.solde': 'Solde',
  'retrait.commission': 'commission',
  'withdrawal.actual.amount': 'Comptabilisé',
  'withdrawal.notice': 'Invite',
  'withdrawal.notice.text': "Avant de transférer, veuillez confirmer que les informations d'adresse de réception sont correctes. Une fois les actifs transférés, ils ne peuvent pas être restitués.",
  'withdrawal.notice.content': '{name}({cp_name}) commission: valeur marchande actuelle {fee_rate}%/stylo, norme minimale: {fee_min} {name}/stylo',
  'withdrawal.submit': 'Soumettre',
  'withdrawal.choice': 'Choisissez une crypto-monnaie',
  'retrait.yzm': 'Code de vérification',
  'retrait.fs': 'Envoyer',
  'withdrawal.qsryzm': "Veuillez saisir le code de vérification de l'e-mail",

  // recevoir
  'recive.method': 'Protocole',
  'recevoir.amount': 'Montant',
  'recive.address': 'Adresse',
  'reçu.date': 'heure',
  'recevoir.status': 'Statut',
  'recive.create.at': 'Heure de la transaction',
  'recevoir.type': 'Type',
  'recevoir.befor': 'avant la transaction',
  'recevoir.balance': 'Solde',
  'recive.freeze': 'Geler',
  'recive.review': 'Révision',
  'recive.success': 'Réussi',
  'recive.reject': 'Refusé',

  // afficher
  'advertise.title': 'Centre de promotion',
  'advertise.shop.title': 'Promotion de la boutique',
  'advertise.shop.status': 'Statut',
  'advertise.shop.expired': 'Expiré',
  'advertise.shop.promotion': 'Promotion',
  'advertise.shop.expire.date': "Date d'expiration",
  'advertise.shop.renew': "Renouvellement de l'annonce",
  'advertise.shop.payable': 'Montant à payer',
  'advertise.shop.explanation': 'Explication de la publicité',
  'advertise.shop.text': "Participer à la promotion publicitaire de la plateforme, augmenter l'exposition du magasin et promouvoir les commandes de transaction",
  'advertise.shop.paynow': 'Renouveler maintenant',
  'advertise.shop.modal.title': 'Confirmer le paiement',
  'advertise.shop.modal.desc': 'Confirmé de payer les frais de promotion',
  'advertise.shop.modal.btn': 'Confirmer le paiement',

  // Liste de souhaits
  'wishlist.title': 'Liste de souhaits',
  'wishlist.delete': 'Supprimer',
  'wishlist.orders': 'Ventes',

  // Suivre la liste
  'followlist.title': 'Suivre la liste',
  'followlist.delete': 'Supprimer',
  'followlist.follow': 'Suivre',

  // magasin
  'store.dashboard': 'Tableau de bord',
  'store.products': 'Produits',
  'store.products.list': 'Liste de produits',
  'store.products.reviews': 'Avis sur les produits',
  'store.orders': 'Commandes',
  'store.wallet': 'Portefeuille',
  'store.message': 'Centre de messagerie',
  'store.setting': 'paramètre',
  'store.order.total.profit': "Produit d'exploitation total estimé",

  //tableau de bord
  'dashboard.store.hour.views': 'Visites en temps réel',
  'dashboard.store.today.views': "Visites d'aujourd'hui",
  'dashboard.product.total': 'Produit total',
  'dashboard.product.today': "Nouveau aujourd'hui",
  'dashboard.order.total': 'Total de la commande',
  'dashboard.sales.total': "Chiffre d'affaires estimé",
  'dashboard.sales.real': 'Ventes réelles',
  'dashboard.sales.pay': 'Paiement total',
  'dashboard.sales.profit': 'Bénéfice total',
  'dashboard.commission.total': 'Revenu total',
  'dashboard.commission.today': "Revenus d'aujourd'hui",
  'dashboard.order.sales': 'Ventes de marchandises',
  'dashboard.sales.list': 'Classement des ventes',
  'dashboard.goods.cate.rate': 'Rapport de classification des produits',
  'dashboard.goods.wish': "J'aime le classement des produits",
  'dashboard.janvier': 'janvier',
  'dashboard.february': 'Février',
  'tableau de bord.march': 'mars',
  'tableau de bord.april': 'avril',
  'tableau de bord.may': 'mai',
  'tableau de bord.juin': 'juin',
  'tableau de bord.juillet': 'juillet',
  'tableau de bord.august': 'août',
  'dashboard.september': 'septembre',
  'dashboard.october': 'Octobre',
  'tableau de bord.novembre': 'novembre',
  'dashboard.december': 'décembre',

  // des produits
  'products.add.new': 'Ajouter un nouveau produit',
  'products.add.from.warehouse': "Ajouter des produits de l'entrepôt",
  'produits.delete': 'Supprimer',
  'produits.add': 'Ajouter',
  'produits.table.img': 'image',
  'products.table.name': 'nom du produit',
  'products.table.category': 'Catégorie',
  'produits.table.wish': "j'aime",
  'produits.table.stock': 'stock',
  'produits.table.prix': 'Prix',
  'products.table.profit': 'Profit',
  'produits.table.action': 'Action',
  'products.search.category': 'Recherche par catégorie',
  'products.back.product': 'Retour à la liste des produits',
  'products.total': 'Total des marchandises',
  'produits.oui': 'Oui',
  'products.no': 'Annuler',
  'products.batch.add': 'Augmentation de lot',
  'products.ask.add': 'Êtes-vous sûr de vouloir ajouter des produits?',
  'products.batch.delete': 'Suppression par lots',
  'products.ask.delete': 'Êtes-vous sûr de vouloir supprimer le produit?',
  'produits.haut': 'Haut',
  'products.syzd': "Haut de la page d'accueil",
  'produits.zdwz': 'Première position',
  'produits.t1': 'Promotion',
  'produits.t2': 'Préféré',
  'produits.t3': 'Boutique',
  'produits.t4': 'aucun',

  // Commentaires
  'reviews.title': 'Avis sur les produits',
  'reviews.product.name': 'Produit',
  'reviews.user.name': 'Utilisateur',
  'reviews.star': 'Note',
  'reviews.comment': 'Contenu du commentaire',
  'reviews.sku': 'Spécifications du produit',
  'avis.imgs': 'Atlas',
  'reviews.created': 'Date',

  // magasin.commande
  'store.order.purchase': 'Acheter maintenant',
  'store.order.purchase.desc': 'Êtes-vous sûr de vouloir payer pour ce produit?',
  'store.order.purchase.yes': 'Payer maintenant',
  'store.order.purchase.no': 'Annuler',
  'store.order.desc': "L'achat de cette commande nécessite un paiement et vous bénéficierez d'avantages une fois la commande terminée. ",
  'store.order.no': 'Numéro de commande',
  'store.order.number': 'Quantité',
  'store.order.buyer': 'Acheteur',
  'store.order.total': 'Montant total',
  'store.order.will.earning': 'Revenu',
  'store.order.profit': 'Bénéfice',
  'store.order.dividends': 'Dividendes',
  'store.order.payment.status': 'Statut du paiement',
  'store.order.seller.buy.status': "Statut de l'achat",
  'store.order.status': 'Statut de la commande',
  'store.order.date': 'date de commande',
  'store.order.purchase.date': "Date d'achat",
  'store.order.action': 'Opération',
  'store.order.purchase.amount': "Montant de l'achat",

  //revenu
  'revenu.create.at': "Temps d'enregistrement",
  'revenu.commande.sn': 'Numéro de commande',
  'revenu.realpay': 'Montant de la commande',
  'revenu.profit': 'revenu',

  //Paramètre
  'setting.avatar': 'Avatar professionnel',
  'setting.upload': 'Télécharger',
  'setting.shop.name': 'Nom de la boutique',
  'setting.shop.phone': 'Téléphone de contact',
  'setting.shop.address': 'Adresse de la boutique',
  'setting.shop.save': 'Enregistrer',
  'setting.upload.pic': 'Télécharger une image',
  'setting.send.pic': "Envoyer l'image",

  //beseller
  'beseller.title': 'Postuler pour devenir marchand',
  'beseller.account': 'Informations sur le compte',
  'beseller.store': 'Informations sur la boutique',
  'beseller.store.name': 'Nom du magasin',
  'beseller.store.address': 'Adresse du magasin',

  //savehome
  'store.home.title': "Paramètres de la page d'accueil",
  'store.home.topimg': 'image supérieure',
  'store.home.banner': 'Image carrousel',
  'store.home.up3': 'Remarques: Au moins 3 téléchargements',
  'store.upload.more': 'Télécharger plus de photos',
  'store.home.columns': 'Colonnes',
  'store.home.bgimg': "Image d'arrière-plan",
  'store.goods.remark': 'Remarque : 5 articles par ligne, 10 articles par assiette maximum',
  'store.home.select': 'Sélectionner un produit',
  'store.home.add': 'Ajouter une colonne',

  //informations personnelles
  'setting.update.user': 'Informations personnelles',
  'setting.update.info': 'Modifier les informations',
  'setting.user.avatar': 'Avatar utilisateur',
  'setting.user.nickname': "Pseudo de l'utilisateur",
  'setting.user.nickname.desc': "Veuillez entrer le surnom de l'utilisateur",

  'setting.safe.title': 'Informations de sécurité',
  'setting.user.passwd': 'Modifier le mot de passe',
  'setting.passwd.title1': 'Ancien mot de passe',
  'setting.passwd.desc1': "Veuillez entrer l'ancien mot de passe",
  'setting.passwd.title2': 'Nouveau mot de passe',
  'setting.passwd.desc2': 'Veuillez entrer un nouveau mot de passe',
  'setting.passwd.title3': 'Confirmer le mot de passe',
  'setting.passwd.desc3': 'Veuillez entrer un mot de passe de confirmation',
  'setting.invitation': 'Inviter des amis',
  'setting.copy': 'Copier',

  'adv.add.products': 'Ajouter des produits promotionnels',
  'adv.list.title': 'Promotion du produit',
  'adv.type': 'Type de promotion',
  'adv.begin.time': 'Heure de la promotion',
  'adv.end.time': 'Heure de fin',
  'adv.status': 'Statut',
  'adv.status.s1': 'Promouvoir',
  'adv.status.s2': 'Fin',
  'adv.add.back': 'Retour à la liste des promus',
  'adv.recharge': 'Recharger les points de promotion',
  'adv.select.goods': 'Sélectionner des marchandises',
  'adv.select.btn': 'Produits en promotion',
  'adv.recharge.title': 'Recharge des points promotionnels',
  'adv.recharge.balance': 'Solde de points',
  'adv.point': 'point',
  'adv.point.rate': 'Rapport de points',
  'adv.recharge.amount': 'Montant de la recharge',
  'adv.recharge.input.amount': 'Entrez le montant de la recharge',
  'adv.select.mod': 'Sélectionner le panneau',
  'adv.mod1': 'Colonnes recommandées',
  'adv.mod2': 'Colonne préférée',
  'adv.mod3': 'Colonne de vente à chaud',
  'adv.mod4': 'Excellente liste',
  'adv.mod5': 'Liste de classement',
  'adv.mod6': 'Promotion détaillée',
  'adv.ge': 'Quantité',
  'adv.xs': 'heure',
  'adv.xzbk': 'Sélectionner la plaque',
  'adv.syme': 'Quota restant',
  'adv.tgsc': 'Durée de la promotion',
  'adv.tgjg': 'Prix promotionnel',
  'adv.syye': 'Solde actuel',
  'adv.tgye': 'Solde des promotions',
  'adv.yj1': 'attendu',
  'adv.yj2': 'Démarrer la promotion',
  'adv.pay.point': 'Points de consommation',

  'wallet.ykcsxf': 'Les frais de gestion ont été déduits',
  'wallet.sxf': 'Frais de service',
  'wallet.zxje': 'Montant minimum',
  'wallet.24hxe': 'limite de 24heures',
  'wallet.desc.text': "En aucun cas, l'agent ne vous demandera de transférer de l'argent sur un compte, ni ne vous demandera de code de vérification. Ne participez pas à des activités illégales telles que l'achat pour le compte d'autrui, le blanchiment d'argent et la collecte de fonds illégale, et méfiez-vous de la fraude en ligne ",
  'wallet.cunbi.text': 'Vous ne pouvez déposer que {name} à cette adresse, les autres actifs ne seront pas récupérés',

  'credit.title': 'Limite de crédit',
  'credit.ed': 'quota',
  'credit.hk': 'Remboursement',
  'credit.kyye': 'Solde disponible',
  'credit.dqqk': 'Arriérés en cours',
  'credit.hkje': 'Montant du remboursement',
  'credit.hkje.desc': 'Veuillez saisir le montant du remboursement',
  'credit.cgje': "Montant de l'achat",
  'credit.qrz': "Aller à l'authentification",
  'credit.srxyj': 'Entrez le montant du paiement par crédit',

  'store.gzl': "Montant de l'attention",
  'store.wxz': 'Illimité',

  'auth.smrz': 'Authentification par nom réel',
  'auth.rzlx': 'Type',
  'auth.sfzm': "Carte d'identité recto",
  'auth.sffm': "Le verso de la carte d'identité",
  'auth.zsxm': 'vrai nom',
  'auth.zjhm': 'Numéro de document',
  'auth.yyzz': 'Licence commerciale',
  'auth.gsmc': "Nom de l'entreprise",
  'auth.shz': 'Audit',
  'auth.ytg': 'Réussi',
  'auth.ybh': 'Refusé',
  'auth.zt': 'état',
  'auth.gr': 'Personnel',
  'auth.gs': 'Entreprise',
  'auth.ljtj': "Demander l'authentification",
  'auth.wrz': 'Non authentifié',

  'credit.qts': 'Voir la description',
  'store.spss': 'Limite supérieure de la marchandise',
  'store.dpfh': 'Stocker les dividendes',
  'store.qydj': 'Niveau de fonds propres',
  'store.level': 'niveau',
  'store.jian': 'pièces',

  'order.notify.email': "En raison du contrôle des risques liés aux e-mails, les e-mails de rappel de commande de la plateforme seront fréquemment interceptés. Afin de les recevoir à temps, il est recommandé d'ajouter {email} à la liste blanche des e-mails de connexion",

  'setting.sjxx': "Informations sur l'entreprise",
  'setting.smrz': 'Authentification par nom réel',
  'setting.aqzx': 'Centre de sécurité',
  'setting.tzxx': 'Informations de notification',
  'setting.dpzx': "Page d'accueil décoration",
  'setting.sjqy': 'Intérêts commerciaux',
  'setting.sjdjqy': "Description des avantages au niveau de l'entreprise",

  'setting.fhbky': "Le dividende actuel n'est pas disponible",
  'setting.spgzyzy': 'Règles et directives du magasin',

  'desc.dqqy': 'Capitaux propres actuels',
  'desc.xjqy': 'Intérêts subordonnés',
  'desc.sjqy_0': 'Limite de crédit du magasin',
  'desc.sjqy_1': 'Stocker les dividendes',
  'desc.sjqy_2': 'Nombre de produits pouvant être publiés',

  'share.yqlb': "Liste d'invitations",
  'share.zmj': 'Vendeur total',
  'share.zdd': 'Total de la commande',
  'share.zsy': 'Revenu total',
  'share.wcl': 'non traité',
  'share.ycl': 'Traité',
  'share.ywc': 'Terminé',
  'share.dpmc': 'Nom du magasin',
  'share.ddl': 'Quantité de la commande',
  'share.jjsy': 'Revenus de courtage',
  'share.zts': 'Nombre total',

  'chat.state': 'état',
  'chat.zx': 'En ligne',
  'chat.ljz': 'Connexion',

  'bind.wallet.title': 'Adresse de retrait',
  'bind.address.title': 'Ajouter une adresse',
  'bind.bj': 'Modifier',
  'bind.sc': 'Supprimer',
  'bind.qrsc': 'Êtes-vous sûr de vouloir supprimer cette adresse?',
  'bind.qbdz': 'adresse du portefeuille',
  'bind.tjsj': 'Ajouter une heure',
  'bind.cz': 'Opération',
  'withdrawal.address.choice': "Veuillez choisir l'adresse de retrait !",

  'order.sxsj': 'Durée effective',
  'order.ljsj': 'immédiatement efficace',
  'order.dssj': 'Temporisé efficace',

  'store.st.zt': 'État de la boutique',
  'store.st.zc': 'normal',
  'store.st.zc.desc': 'Votre magasin est normal, continuez comme ça',
  'store.st.yc': 'Exception',
  'store.st.yc.desc': 'Votre boutique a des commandes anormales ou a reçu des réclamations clients',
  'store.st.dj': 'Geler',
  'store.st.dj.desc': 'Votre magasin présente un certain risque d"exploitation et les fonds sont gelés"',
  'store.st.jy': 'désactivé',
  'store.st.jy.desc': 'Votre boutique a enfreint les accords et règles liés à la plateforme et a été désactivée',

  'task.sy': "Page d'accueil",
  'task.rwlbo': 'Liste des tâches',
  'task.rwxqo': 'Détails de la tâche',
  'task.ljcy': 'Participer maintenant',
  'task.gdrw': 'Plus de tâches',
  'task.rwlb': 'Liste des tâches de saisie',
  'task.qbrw': 'Toutes les tâches',
  'task.wcyd': "J'ai participé à",
  'task.qdz': 'Récupérer des commandes',
  'task.ywc': 'Terminé',
  'task.djy': 'à échanger',
  'task.ddje': 'Montant de la commande',
  'task.cyrs': 'Nombre de participants',
  'task.ewsy': 'Avantages supplémentaires',
  'task.jzsj': 'Délai',
  'task.ckxq': 'Afficher les détails',
  'task.rwxq': 'Détails de la tâche',
  'task.cylb': 'Liste des participants',
  'task.qdcyrw': 'Êtes-vous sûr de vouloir participer à cette tâche de commande ?',
  'task.sd': 'Oui',
  'task.bue': 'non',

  'task.wdsy': 'Revenu',
  'task.wdfh': 'Dividendes',
  'task.wdqd': 'Récupérer la commande',
  'task.dd.leixin': 'Type de commande',
  'task.dd.qdrw': 'Tâche de commande rapide',
  'task.dd.ptdd': 'Commande ordinaire',

  'flow.lltg': 'Promotion de flux',
  'flow.gmll': 'Acheter un package de données',
  'flow.dqtc': 'Paquet actuel',
  'flow.tc1': 'Paquet',
  'flow.sy2': 'restant',
  'flow.tian3': 'jour',
  'flow.jlzs': "Nombre total d'enregistrements",
  'flow.lltc': 'Paquet de flux',
  'flux.ll': 'flux',
  'flow.gmsj': "Temps d'achat",
  'flow.tcjg': 'Prix du forfait',
  'flow.sjzf': 'paiement réel',
  'flow.tcsm': 'Description du paquet',
  'flow.tcsm.sm': "L'achat de ce package augmentera la visibilité du magasin et augmentera le trafic client. Afin d'obtenir plus de commandes et d'augmenter les revenus du magasin. ",

  'flow.tcmc': 'Nom du package',
  'flow.gmsc': "Durée d'achat",
  'flow.zje': 'Montant total',
  'flow.jzsj': 'Délai',
  'flow.gmrq': "Date d'achat",
  'flow.tian1': 'jour',
  'flow.zhou1': 'semaine',
  'flow.yue1': 'mois',

  'share.dj': 'niveau',
  'share.hz': 'Transfert',
  'share.hzd': 'Transférer vers',
  'share.qbye': 'solde du portefeuille',
  'share.tgje': 'Montant de la promotion',
  'share.hzje': 'Montant du transfert',
  'share.qsrhzje': 'Veuillez entrer le montant du transfert',

  '_inv._title': "Description de l'invitation",
  '_inv._t1': '1. Invitation et relation invitée',
  '_inv._desc1': "A invite B, B invite C, C invite D. Le niveau le plus élevé est 4, si D invite à nouveau E, alors E n'a rien à voir avec A. ",
  '_inv._t2': "2. L'invitant reçoit une récompense",
  '_inv._desc2': 'A reçoit 4 % du montant total réalisé par la commande B, A reçoit 2 % du montant total réalisé par la commande C, et A reçoit 1 % du montant total réalisé par la commande D',

  '_index._gm': 'Achat',
  '_index._sm': "Description : Augmenter l'exposition du magasin et attirer du trafic",

  '_n.dlyzc': 'Connexion et inscription',
  '_n.hy4': 'Il y en a 4 de plus',
  '_n.ckgd': 'Voir plus',
  '_n._cxhzk': 'Promotions et réductions',
  '_n._cwyp': 'Fournitures pour animaux',
  '_n._tjsp': 'Offre spéciale',
  '_n._rmfl': 'Catégories populaires',
  '_n._tjsp2': 'Produits recommandés',
  '_n._rxsp': 'Produits recommandés',
  '_n._rmsp': 'Articles populaires',
  '_n._gdsp': 'Plus de produits',
  '_n._yzsj': 'Marchand de qualité',

  '_n._zxsc': 'Centre commercial en ligne',
  '_n._fkyys': 'Paiement et expédition',
  '_n._xsgz': 'Règles de vente',
  '_n._hhjth': 'Échanges et retours',
  '_n._lxwm': 'Contactez-nous',
  '_n._sczc': 'politique du centre commercial',
  '_n._tkytj': 'Conditions générales',
  '_n._tuzc': 'Politique de retour',
  '_n._zczc': 'Politique de support',
  '_n._yszc': 'Politique de confidentialité',
  '_n._scjj': 'Présentation du centre commercial',
  '_n._ljgm': 'Acheter maintenant',

  '_n2._rxsp': 'Articles les plus vendus',
  '_n2._pl': 'Commentaire',
  '_n2._sjxx': 'Informations commerciales',
  '_n2._rhsh': 'Comment recevoir la marchandise',
  '_n2._xsxd': 'Commande en ligne',
  '_n2._jscldd': 'Service client dédié',
  '_n2._mfps': 'Livraison gratuite',
  '_n2._2xsfh': 'Livraison éclair',
  '_n2._1d5tdd': 'Arrivée sous 1 à 5 jours',
  '_n2._tkzd': 'Courrier express',
  '_n2._ckfh': "Expédier depuis l'entrepôt",
  '_n2._zcps': 'Entrepôt à proximité',
  '_n2._jsd': 'Point de réception',
  '_n2._qsjps': 'Livraison dans le monde entier',
  '_n2._thtj': 'Conditions de retour',
  '_n2._thtjsm': "Vous pouvez échanger ou retourner le produit dans un délai de 14 jours à compter de la date d'achat",
  '_n2._gycp': 'À propos du produit',
  '_n2._yc': 'Masquer',
  '_n2._tiao': 'Tiao',
  '_n2._sypl': 'Tous les commentaires',
  '_n2._lxfk': 'Laisser des commentaires',
  '_n2._xzspgg': 'Veuillez sélectionner les spécifications du produit',
  '_n2._qxdl': "Veuillez d'abord vous connecter",
  '_n2._rnsj': 'Comme vous pouvez le voir',
  '_n2._rx': 'Vente chaude',

  '_n3._qrdd': 'Confirmer la commande',
  '_n3._njsp': '{num} éléments',
  '_n3._ddzf': 'Paiement de la commande',
  '_n3._wydlsp': 'Bienvenue pour vous connecter au magasin',

  '_n3._sjwfgm': 'Le commerçant ne peut pas acheter de marchandises',
  '_n3._sjbnlt': 'Les entreprises ne peuvent pas discuter',
  '_chat._hc': 'Retirer',

  '_zc._sryxdz': 'Veuillez entrer votre email',
  '_zc._yxgscw': "Erreur de format d'e-mail",
  '_zc._qsrmm': 'Veuillez entrer le mot de passe',
  '_zc._lcmmbxd': 'Deux mots de passe sont incohérents',
  '_zc._hdyzwtg': 'Veuillez vérifier via le curseur',
  '_zc._qsryqm': "Veuillez saisir le code d'invitation",
  '_zc._yhzh': 'Enregistrer un compte utilisateur',
  '_zc._qzc': "Aller à l'inscription",
  '_zc._srdpm': 'Veuillez saisir le nom du magasin',

  '_zc._gszz': "Qualification de l'entreprise",
  '_zc._ptzz': 'Qualifications liées à la plateforme',

  '_dd._tijd': 'Soumission de la commande',
  '_dd._xzdz': "Veuillez sélectionner l'adresse de livraison",
  '_dd._zfz': 'Paiement en cours',

  '_wd._zf': 'paiement',
  '_wd._cz': 'Recharger',
  '_wd._txsq': 'Demande de retrait',
  '_wd._ddsy': 'Revenu de commande',
  '_wd._cgzf': 'Achat',
  '_wd._txbh': 'Rejeter',
  '_wd._txtg': 'Réussi',
  '_wd._ddtk': 'Remboursement de la commande',
  '_wd._yehz': 'Transfert',
  '_wd._gmll': 'Acheter du trafic',
  '_wd._zjkc': 'déduction du loyer',
  '_wd._fk': 'Très bien',
  '_wd._ns': 'Taxe',
  '_wd._bzj': 'Marge',

  '_xxtz._zntz': 'Notification du site',
  '_xxtz._fh': 'Retour',
  '_xxtz._jzgd': 'Charger plus',
  '_xxtz._mysj': 'Plus rien',

  '_sjsy._jrdd': 'Commande du jour',
  '_sjsy._rxse': 'Ventes quotidiennes',

  '_sjsy._xh': 'Index',

  '_wd._nbjy': 'Transfert interne',
  '_wd._qsruid': "Veuillez saisir l'UID du bénéficiaire",
  '_wd._je': 'Montant',
  '_wd._qsrhzje': 'Veuillez saisir le montant du transfert',
  '_wd._skr': 'Bénéficiaire',
  '_wd._nbhzsm': 'Pour transférer en interne vers les utilisateurs de la plateforme, veuillez vérifier attentivement les informations sur le bénéficiaire',

  '_st._spxlphb': 'Classement des ventes de produits',
  '_st._czsp': 'Réinitialiser la recherche',

  '_st._pljrgwc': 'Rejoindre tout',

  '_sj._yxz': 'Sélectionné',
  '_sj._hjzje': 'Montant total total',
  '_sj._zdcr': 'Dépôt automatique',

  '_wd._ddfh': 'Dividende',

  '_dd._csqx': 'Annulation de commande',

  '_tx._yhktx': 'Retrait bancaire',
  '_tx._sryhmc': 'Veuillez saisir le nom de la banque',
  '_tx._srskrmc': 'Veuillez saisir le nom du bénéficiaire',
  '_tx._srskzh': 'Veuillez saisir le numéro de compte de paiement',
  '_tx._sryhluh': 'Veuillez saisir le numéro de routage bancaire',
  '_tx._sryhdm': 'Veuillez saisir le code banque',
  '_tx._sryhdz': "Veuillez saisir l'adresse de la banque",
  '_tx._txje': 'Montant du retrait',
  '_tx._srtxje': 'Veuillez saisir le montant du retrait',
  '_tx._txkc': 'Frais de retrait {_sxf}%',

  '_yye._yyye': 'Ventes mensuelles',
  '_yye._ysy': 'Revenu mensuel',

  '_sj._hjzsy': 'Revenu total total',

  '_yhdl._dl': 'Connexion',
  '_yhdl._zc': 'Créer un compte',

  '_zdy._24zrkf': 'Nous sommes là pour vous aider 24h/24 et 7j/7',
  '_zdy._zhsz': 'Paramètres du compte',
  '_zdy._mfps': "Pour une durée limitée, vous pouvez bénéficier d'une livraison gratuite en 2 jours ouvrables sur des milliers d'articles dans certaines zones. ",
  '_zdy._gmsp': 'Achetez de nouveaux produits',
  '_zdy._czyh': 'Bonnes affaires, profitez de vos produits préférés',
  '_zdy._xsgw': 'Achats à durée limitée ! Commandez avant le 12 mai à midi HE pour la livraison gratuite. Livré à votre porte par une voiture dédiée ! ',
  '_zdy._ssgjc': 'Entrez la recherche par mot-clé',

  '_zdy._rmjx': 'Vos meilleurs choix',

  '_dd._plcz': 'Opération par lots',
  '_dd._plcg': 'Achat groupé',
  '_dd._qgxcgd': 'Veuillez vérifier la commande que vous devez acheter',
  '_dd._yxddbcg': "La commande sélectionnée ne nécessite pas d'achat",
  '_dd._qdyplcgm': 'Êtes-vous sûr de vouloir acheter la commande sélectionnée en gros ? ,Quantité :{n}',
  '_dd._qdcg': "Confirmer l'achat",
  '_dd._qx': 'Annuler',
  '_dd._plcgwc': 'Achat par lots terminé, réussi : {s}/{t}',
  '_dd._zzcgz': 'Achats',
  '_st._czhk': 'Recharger et rembourser',
  '_st._dkje': 'montant du prêt',
  '_st._dkjl': 'historique de prêt',
  '_st._hkfs': 'Remboursement',
  '_st._hkje': 'Montant du remboursement',
  '_st._jrong': 'Financement du centre commercial',
  '_st._ljsq': 'postuler immédiatement',
  '_st._qrshkje': 'Veuillez saisir le montant du remboursement',
  '_st._qsrdkje': 'Veuillez saisir le montant du prêt',
  '_st._shz': 'en cours de révision',
  '_st._sqdklx': 'prêt',
  '_st._sqhk': 'Faire une demande de remboursement',
  '_st._sqhklx': 'remboursement',
  '_st._sqjk': 'demander un prêt',
  '_st._sqlx': 'taper',
  '_st._ybh': 'rejeter',
  '_st._yjk': 'Emprunté',
  '_st._ytg': 'passer',
  '_st._zzhk': 'En attente de remboursement',

  '_st._dqed': 'Montant du prêt',
  '_st._ccdked': 'Impossible de dépasser la limite de prêt',
  '_st._tjsqz': 'La candidature est en cours de soumission',
  '_st._dklx': 'Intérêt',
  '_st._dkll': "Taux d'intérêt du prêt : {n}%",
  '_kdts._ts': 'Ouvert pendant {t} jours',
  '_jltg._tgje': 'Investissement publicitaire',

  '_st._yqk': 'Arrérage',
  '_st._qb': 'Tous',

  '_spxx._spbm': 'code',

  '_scrz._yyzz': 'Licence commerciale'
}
